import { LoginSysAdmin } from '../../../role/components/login-sys-admin'
import { LayoutAdminNode } from './admin-node/layout-admin-node'
import { LayoutApp } from './app/layout-app'
import { LayoutAuthSession } from './auth-session/route-auth-session'
import { LayoutAuthStatus } from './auth-status/layout-auth-status'
import { LayoutDashboard } from './dashboard/layout-dashboard'
import { LayoutLogin } from './login/layout-login'
import { LayoutOrganization } from './organization/layout-organization'
import { LayoutProvider } from './provider/layout-provider'
import { LayoutSendgridEvent } from './sendgrid-event/layout-sendgrid-event'

export const LayoutAdmin = () => {
  return (
    <LoginSysAdmin>
      <LayoutDashboard />
      <LayoutApp />
      <LayoutAuthStatus />
      <LayoutAuthSession />
      <LayoutLogin />
      <LayoutSendgridEvent />
      <LayoutProvider />
      <LayoutOrganization />
      <LayoutAdminNode />
    </LoginSysAdmin>
  )
}
