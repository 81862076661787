import React from 'react'
import { useRouteMatch } from '../../../../../../../node_modules/react-router-dom/cjs/react-router-dom.min'
import { useApiListAsset } from '../../../../../../api-new/state/content'
import { IconList } from '../../../../../../shared/icon/icon'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'
import { Menu, MenuPurge, MenuRestore } from '../../../../../../shared/menu/menu'
import { useRoleOrgNode } from '../../../../../../role/hook/role-org'
import { TableAgClient } from '../../../../../../shared/table-builder/table-ag-client'
import { ColumnMenu, ColumnText } from '../../../../../../shared/table-builder/table-columns'

export const AssetRecycle = ({ nodeID }) => {
  const { url } = useRouteMatch()

  const apiListAsset = useApiListAsset()

  const roleOrgNode = useRoleOrgNode()

  const handleReady = async () => apiListAsset.get(`${nodeID}/node/deleted`)
  const handleDestroyed = () => apiListAsset.init()

  const handleRestore = async id => {
    await apiListAsset.put(`${id}/restore`)
    await apiListAsset.get(`${nodeID}/node/deleted`)
  }

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconList} text="recycle bin">
        <CardFullHeightScrollY title="recycle bin">
          <TableAgClient apiList={apiListAsset} onReady={handleReady} onDestoryed={handleDestroyed}>
            <ColumnText field="name" />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuRestore onClick={() => handleRestore(row.id)} show={roleOrgNode.accessDelete} />
                  <MenuPurge to={`${url}/${row.id}/purge`} show={roleOrgNode.accessPurge} />
                </Menu>}
            />
          </TableAgClient>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
