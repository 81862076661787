import React, { useCallback, useContext, useMemo, useState } from 'react'
import { getAccessToken, getRefreshToken, removeTokens, setAccessToken, setRefreshToken } from '../storage/auth'
import { useApiFormAuthImpersonate, useApiFormAuthLogout } from '../api-new/state/auth'
import { useApiFormAuthRefresh } from '../api-new/state/auth'
import { useLocation } from '../../node_modules/react-router-dom/cjs/react-router-dom.min'

export const AuthContext = React.createContext()

export const useAuthContext = () => useContext(AuthContext)

export const AuthProvider = ({ children }) => {
  const [jwtClaims, updateJwtClaims] = useState({})

  const state = useMemo(
    () => {
      return {
        get isAuth() {
          return !!this.accessToken
        },

        get accessToken() {
          return getAccessToken()
        },
        get refreshToken() {
          return getRefreshToken()
        },

        jwtClaims: jwtClaims,
        role: jwtClaims.role, //remove
        loginID: jwtClaims.loginID,
        userID: jwtClaims.userID,
        isSysAdmin: jwtClaims.isSysAdmin || false,
        isSysRoot: jwtClaims.isSysRoot || false,
        isSession: jwtClaims.isSession || false,

        organizationID: jwtClaims.organizationID,
        organizationName: jwtClaims.organizationName,
        organizationType: jwtClaims.organizationType,

        featureHeatmap: jwtClaims.featureHeatmap,
        featureTradeShow: jwtClaims.featureTradeShow,
        featureAI: jwtClaims.featureAI
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [jwtClaims]
  )

  const setAuthToken = authToken => {
    let claimsData = atob(authToken.accessToken.split('.')[1])
    const jwtClaims = JSON.parse(claimsData)
    updateJwtClaims(jwtClaims)

    setAccessToken(authToken.accessToken, jwtClaims.isSession)
    setRefreshToken(authToken.refreshToken, jwtClaims.isSession)
  }

  const actions = {
    clearJwtClaims: () => updateJwtClaims({}),

    setSignoutURL: signoutURL => sessionStorage.setItem('signout-url', signoutURL),
    getSignoutURL: () => sessionStorage.getItem('signout-url'),
    removeSignoutURL: () => sessionStorage.removeItem('signout-url'),

    setAuthToken: authToken => setAuthToken(authToken),

    checkExpRefresh: async () => {
      let now = Math.floor(Date.now() / 1000)
      if (now > state.jwtClaims.exp) {
        await actions.refresh()
      }
    }
  }

  return (
    <AuthContext.Provider value={{ actions: actions, state: state, setAuthToken: setAuthToken }}>
      {children}
    </AuthContext.Provider>
  )
}

export const useRefresh = () => {
  const authContext = useAuthContext()
  const apiFormAuthRefresh = useApiFormAuthRefresh()

  const refresh = useCallback(async () => {
    if (authContext.state.isAuth) {
      const authToken = await apiFormAuthRefresh.get(authContext.state.refreshToken)
      authContext.setAuthToken(authToken)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const refreshRecreate = useCallback(async refreshToken => {
    const authToken = await apiFormAuthRefresh.get(`${refreshToken}/recreate`)
    authContext.setAuthToken(authToken)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const refreshImpersonate = useCallback(async refreshToken => {
    const authToken = await apiFormAuthRefresh.get(refreshToken)
    authContext.setAuthToken(authToken)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { refresh, refreshRecreate, refreshImpersonate }
}

export const useLogout = () => {
  const { pathname } = useLocation()

  const authContext = useAuthContext()

  const apiFormAuthLogout = useApiFormAuthLogout()

  return async () => {
    await apiFormAuthLogout.delete(authContext.state.refreshToken)
    removeTokens(authContext.state.isSession)
    authContext.actions.clearJwtClaims()

    let url = '/auth/email'

    if (pathname) {
      url += `?redirect=${pathname}`
    }
    let signoutUrl = authContext.actions.getSignoutURL()

    if (signoutUrl) {
      authContext.actions.removeSignoutURL()

      const complete = () =>
        setTimeout(() => {
          document.body.removeChild(iframe)
          window.location.replace(url)
        }, 1000)

      let iframe = document.createElement('iframe')
      iframe.setAttribute('id', 'signoutID')
      iframe.setAttribute('style', 'display:none;')
      iframe.setAttribute('src', signoutUrl)
      iframe.onload = complete
      iframe.onerror = complete
      document.body.appendChild(iframe)
    } else {
      window.location.replace(url)
    }
  }
}

export const useImpersonateUser = () => {
  const apiFormAuthImpersonate = useApiFormAuthImpersonate()

  return useCallback(async (organizationID, userID) => {
    return await apiFormAuthImpersonate.post({ organizationID, userID })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
