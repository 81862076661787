import React from 'react'
import { Route } from '../../../../../node_modules/react-router-dom/cjs/react-router-dom.min'
import { AuthSessionList } from './page/auth-session-list'

export const LayoutAuthSession = () => {
  return (
    <React.Fragment>
      <Route path="/admin/auth-session/list" component={AuthSessionList} />
    </React.Fragment>
  )
}
