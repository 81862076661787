/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react'
import { ModalDialog } from '../../../../../../shared/component/modal-dialog'
import { ButtonPrimary, ButtonSecondary } from '../../../../../../shared/component/button'
import Papa from 'papaparse'

export const ModalExample = ({ target, onClose }) => {
  const handleDownload = () => {
    let hold = Papa.unparse([
      // ['Email', 'Firstname', 'Lastname', 'Role', 'Group', 'Content', 'Template', 'Library'],
      ['Email', 'Firstname', 'Lastname', 'Role', 'Group', 'Content', 'Template'],
      ['', '', '', '', '', '', ''],
      ['', '', '', '', '', '', ''],
      ['', '', '', '', '', '', '']
    ])
    var a = document.getElementById('a')
    var file = new Blob([hold], { type: 'text/csv' })
    a.href = URL.createObjectURL(file)
    a.download = 'import'
    a.click()
  }

  return (
    <React.Fragment>
      <a href="#" id="a" hidden />
      <ModalDialog target={target} header="Import Example" large>
        <div className="table-responsive">
          <table className="table table-sm">
            <thead>
              <tr>
                <td>Email</td>
                <td>FirstName</td>
                <td>LastName</td>
                <td>Role</td>
                <td>Group</td>
                <td>Content</td>
                <td>Template</td>
                {/* <td>Library</td> */}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>user1@example.com</td>
                <td>first1</td>
                <td>last1</td>
                <td>role</td>
                <td>group</td>
                <td>content</td>
                <td>template</td>
                {/* <td>library</td> */}
              </tr>
              <tr>
                <td>user2@example.com</td>
                <td>first2</td>
                <td>last2</td>
                <td>role</td>
                <td>group</td>
                <td>content</td>
                <td>template</td>
                {/* <td>library</td> */}
              </tr>
              <tr>
                <td>user3@example.com</td>
                <td>first3</td>
                <td>last3</td>
                <td>role</td>
                <td>group</td>
                <td>content</td>
                <td>template</td>
                {/* <td>library</td> */}
              </tr>
            </tbody>
          </table>
        </div>
        <ButtonSecondary text="close" onClick={onClose} />
        <ButtonPrimary text="download template" onClick={handleDownload} />
      </ModalDialog>
    </React.Fragment>
  )
}
