import React, { useCallback, useMemo, useRef } from 'react'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { IconOrganization, IconUsers } from '../../../../../shared/icon/icon'
import {
  Menu,
  MenuAdd,
  MenuDelete,
  MenuEdit,
  MenuExportCsv,
  MenuExportExcel,
  MenuItem,
  MenuRecycle,
  MenuTableSettingToggle
} from '../../../../../shared/menu/menu'
import { CardFullHeightScrollY } from '../../../../../shared/card/card-full-height-scroll-y'
import { useApiListLogin } from '../../../../../api-new/state/content'
import { useRoleSysLogin } from '../../../../../role/hook/role-sys'
import { ColumnText, ColumnDate, ColumnMenu, ColumnYesNo, ColumnSelector } from '../../../../../shared/table-builder/table-columns'
import { TableAgClient } from '../../../../../shared/table-builder/table-ag-client'
import moment from 'moment'
import { BadgeSecondary, BadgeSuccess, BadgeWarning } from '../../../../../shared/component/badge'

const BadgeStatus = ({ userLogin }) => {
  let value = useMemo(
    () => {
      if (userLogin.lastActive) {
        let now = moment()
        let lastActive = moment(userLogin.lastActive)
        let minutes = now.diff(lastActive, 'minutes')

        if (minutes < 10) {
          return <BadgeSuccess value="Online" />
        } else if (minutes < 30) {
          return <BadgeWarning value="Away" />
        } else {
          return <BadgeSecondary value="Offline" />
        }
      }

      return <BadgeSecondary value="Offline" />
    },
    [userLogin]
  )

  return (
    <React.Fragment>
      {value}
    </React.Fragment>
  )
}

export function LoginList() {
  const apiListLogin = useApiListLogin()
  const roleSysLogin = useRoleSysLogin()

  const tableRef = useRef()
  const handleExportCsv = () => tableRef.current.exportCsv()
  const handleExportExcel = () => tableRef.current.exportExcel()
  const handleTableSettingToggle = () => tableRef.current.toggleSideBar()

  const handleReady = async () => await apiListLogin.get()
  const handleDestroyed = () => apiListLogin.init()

  const HeaderMenu = () =>
    <Menu>
      <MenuAdd to="/admin/login/list/add" show={roleSysLogin.accessCreate} />
      <MenuExportCsv onClick={handleExportCsv} show />
      <MenuExportExcel onClick={handleExportExcel} show />
      <MenuTableSettingToggle onClick={handleTableSettingToggle} show />
      <MenuRecycle to="/admin/login/list/recycle" show={roleSysLogin.accessDelete} />
    </Menu>

  const comparatorStatus = useCallback((value1, value2, node1, node2) => {
    if (node1.data.invitePending || node2.data.invitePending) {
      if (node2.data.invitePending) {
        return 1
      } else {
        return -1
      }
    }

    if (!node1.data.lastActive && !node2.data.lastActive) {
      return -1
    }

    let date1 = new Date(node1.data.lalastActivestAuth)
    let date2 = new Date(node2.data.lastActive)

    if (date1 > date2) {
      return 1
    } else {
      return -1
    }
  }, [])

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconUsers} text="LOGINS">
        <CardFullHeightScrollY title="login" HeaderMenu={HeaderMenu}>
          <TableAgClient ref={tableRef} apiList={apiListLogin} onReady={handleReady} onDestoryed={handleDestroyed}>
            <ColumnText field="email" />
            <ColumnText field="firstName" />
            <ColumnText field="lastName" />
            <ColumnDate field="createdAt" />

            <ColumnSelector field="lastActive" header="Status" selector={row => <BadgeStatus userLogin={row} />} comparator={comparatorStatus} />
            <ColumnSelector
              field="lastActive"
              header="Last Active"
              selector={row => (row.lastActive ? `${moment(row.lastActive).fromNow(true)} ago` : 'Never')}
            />

            <ColumnYesNo field="isSysAdmin" header="Sys Admin" hide />
            <ColumnYesNo field="isSysRoot" header="Sys Root" hide />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuEdit to={`/admin/login/list/edit/${row.id}`} show={roleSysLogin.accessUpdate} />
                  <MenuItem
                    Icon={IconOrganization}
                    text="organizations"
                    to={`/admin/login/list/edit/${row.id}/organizations`}
                    show={roleSysLogin.accessUpdate}
                  />
                  <MenuDelete to={`/admin/login/list/edit/${row.id}/delete`} show={roleSysLogin.accessDelete} />
                </Menu>}
            />
          </TableAgClient>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
