import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

export const useFitToPage = () => {
  return useCallback(agGrid => {
    if (agGrid.api) {
      let columnState = agGrid.api.getColumnState().filter(column => column.colId !== 'menu')

      columnState.forEach(column => {
        column.flex = 1
      })

      agGrid.api.applyColumnState({ state: columnState })
    }
  }, [])
}

export const useFitToColumns = () => {
  return useCallback(agGrid => {
    if (agGrid.api) {
      let columnState = agGrid.api.getColumnState()
      let columns = agGrid.api.getColumns()

      if (columns) {
        let sizeColumns = columns.filter(column => column.colId !== 'menu').map(column => column.getId())
        agGrid.api.autoSizeColumns(sizeColumns, false)
      }

      agGrid.api.applyColumnState(columnState)
    }
  }, [])
}

export const useGetQueryValues = () => {
  return useCallback(() => {
    const searchValues = new URLSearchParams(document.location.search)

    return {
      search: searchValues.get('search') || '',
      index: parseInt(searchValues.get('index') || 0),
      sortCol: searchValues.get('sortCol'),
      sortDir: searchValues.get('sortDir')
    }
  }, [])
}

export const useSetQueryValues = () => {
  const history = useHistory()

  return useCallback((search, index, sortCol, sortDir) => {
    const searchValues = new URLSearchParams(document.location.search)

    if (search !== undefined) {
      if (search) {
        searchValues.set('search', search)
      } else {
        searchValues.delete('search')
      }
    }

    if (index !== undefined) {
      searchValues.set('index', index)
    }

    if (sortCol !== undefined) {
      if (sortCol) {
        searchValues.set('sortCol', sortCol)
      } else {
        searchValues.delete('sortCol')
      }
    }

    if (sortDir !== undefined) {
      if (sortDir) {
        searchValues.set('sortDir', sortDir)
      } else {
        searchValues.delete('sortDir')
      }
    }

    history.replace({ search: `${searchValues}` })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
