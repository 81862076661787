import { forwardRef,  useImperativeHandle, useMemo, useRef, useState } from 'react'
import { AgGridReact } from 'ag-grid-react'

import { ModuleRegistry } from 'ag-grid-community'
import { LicenseManager } from 'ag-grid-enterprise'
import { AllEnterpriseModule } from 'ag-grid-enterprise'
import { themeAlpine, colorSchemeDarkBlue } from 'ag-grid-community'
import {} from '../component/filter'
import { Footer } from './component/table-footer'
import { usePageContext } from '../../context/page.context'
import { useFitToPage } from './hooks'

LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_KEY)
ModuleRegistry.registerModules([AllEnterpriseModule])

export const TableAG = forwardRef(
  (
    {
      funcRef,
      columns,
      data,
      loading,
      rowModelType,
      infiniteInitialRowCount,
      cacheBlockSize,
      cacheOverflowSize,
      maxBlocksInCache,

      sortFirst,
      sortCol,
      sortDesc,

      onReady,
      onRefresh,
      onRowClicked,

      onScrollVertical,
      onSortChanged,
      onDataUpdated,
      onDestoryed
    },
    ref
  ) => {
    const pageContext = usePageContext()

    const defaultColDef = useMemo(() => {
      return {
        minWidth: 200,
        sortable: true,
        enablePivot: true,
        enableRowGroup: true
      }
    }, [])

    const gridOptions = useMemo(() => {
      return {}
    }, [])

    const autoSizeStrategy = {
      // type: 'fitGridWidth'
      // type: 'fitProvidedWidth'
      // type: 'fitCellContents'
    }

    const sideBarConfig = useMemo(() => {
      return {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
            minWidth: 225,
            maxWidth: 225,
            width: 225
          }
        ],
        defaultToolPanel: 'columns',
        position: 'right'
      }
    }, [])

    const [sideBar, updateSideBar] = useState(null)

    useImperativeHandle(funcRef, () => ({
      exportCsv: () => ref.current.api.exportDataAsCsv(),
      exportExcel: () => ref.current.api.exportDataAsExcel(),
      toggleSideBar: () => {
        if (sideBar) updateSideBar(null)
        else updateSideBar(sideBarConfig)
      }
    }))

    const [pagination, updatePagination] = useState(false)

    const handleRefresh = () => onRefresh && onRefresh(ref.current)

    const [paginationValues, updatePaginationValues] = useState({})

    const handlePaginationChanged = agGrid => {
      let rowCount = agGrid.api.getDisplayedRowCount()
      const currentPage = agGrid.api.paginationGetCurrentPage()
      let pageSize = agGrid.api.paginationGetPageSize()
      let totalPages = agGrid.api.paginationGetTotalPages()

      updatePaginationValues({ rowCount, currentPage, pageSize, totalPages })
    }

    const theme = useMemo(
      () => (pageContext.theme.isDark ? themeAlpine.withPart(colorSchemeDarkBlue).withParams({ backgroundColor: 'rgb(25,27,31)' }) : themeAlpine),
      [pageContext.theme.isDark]
    )

    const handleRowClicked = row => onRowClicked && onRowClicked(row.data)

    const containerRef = useRef()

    const fitToPage = useFitToPage()
    // const fitToColumns = useFitToColumns()

    const handleReady = async agGrid => {
      onReady && (await onReady(agGrid))

      fitToPage(agGrid)

      // let allColumnWidth = agGrid.api.getColumns().filter(column => column.visible).reduce((sum, column) => (sum += column.actualWidth), 0)
      // let containerWidth = containerRef.current.offsetWidth

      // if (allColumnWidth > containerWidth) {
      //   debugger
      //   fitToColumns(agGrid)

      //   // setTimeout(() => {
      //   //   debugger
      //   //   // fitToPage(agGrid)
      //   //   // fitToColumns(agGrid)
      //   // }, 1000)
      // } else {
      //   debugger
      //   fitToPage(agGrid)
      // }
    }

    const handleBodyScroll = params => {
      if (params.direction === 'vertical') {
        if (onScrollVertical) {
          let index = ref.current?.api?.getFirstDisplayedRowIndex()
          onScrollVertical(index)
        }
      }
    }

    return (
      <div ref={containerRef} className="d-flex flex-column h-100">
        <div className="flex-fill">
          <AgGridReact
            ref={ref}
            theme={theme}
            defaultColDef={defaultColDef}
            autoSizeStrategy={autoSizeStrategy}
            gridOptions={gridOptions}
            columnDefs={columns}
            rowData={data}
            loading={loading}
            rowModelType={rowModelType}
            pagination={pagination}
            paginationAutoPageSize={pagination}
            rowBuffer={0}
            cacheBlockSize={cacheBlockSize}
            cacheOverflowSize={cacheOverflowSize}
            infiniteInitialRowCount={infiniteInitialRowCount}
            maxBlocksInCache={maxBlocksInCache}
            overlayLoadingTemplate={`<div class="spinner-border text-primary" role="status"><span class="visually-hidden">Loading...</span></div>`}
            suppressPaginationPanel={true}
            onGridReady={handleReady}
            onPaginationChanged={handlePaginationChanged}
            pivotMode={false}
            sideBar={sideBar}
            rowGroupPanelShow={sideBar && 'always'}
            onRowClicked={handleRowClicked}
            onBodyScroll={handleBodyScroll}
            onSortChanged={onSortChanged}
            onRowDataUpdated={onDataUpdated}
            onGridPreDestroyed={onDestoryed}
          />
        </div>
        <div>
          <Footer gridRef={ref} pagination={pagination} paginationValues={paginationValues} onPaginationChange={updatePagination} onRefresh={handleRefresh} />
        </div>
      </div>
    )
  }
)
