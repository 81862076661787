import axios from 'axios'
import { getAccessToken, getRefreshToken, removeTokens, setAccessToken } from '../storage/auth'
import DeviceDetector from "device-detector-js";

const checkAccessTokenExp = async accessToken => {
  
  let token = accessToken.split('.')[1]
  let claims = atob(token)

  let claimsObj = JSON.parse(claims)

  let now = new Date()

  let refreshExp = new Date(claimsObj.refreshTokenExp * 1000)
  let accessExp = new Date(claimsObj.exp * 1000)

  
  if (now > refreshExp) {
    removeTokens()
    window.location = '/auth/email'
  }

  if (now > accessExp) {
    
    let refreshToken = getRefreshToken()
    let endpoint = `${process.env.REACT_APP_API_AUTH}/api/v1/auth-refresh/${refreshToken}`

    try {
      let result = await axios.get(endpoint)

      if (result.status === 200) {
        accessToken = result.data.accessToken
        setAccessToken(accessToken)
      } else if (result.status === 401) {
        removeTokens()
        window.location = '/auth/email'
      } else {
        removeTokens()
        window.location = '/auth/email'
      }
    } catch (exp) {
      removeTokens()
      window.location = '/auth/email'
    }     
  }
  
  return accessToken
}

const getConfig = async (config, contentType = 'application/json') => {
  config = config || {}

  let headers = {}

  let accessToken = getAccessToken()

  if (accessToken) {
    accessToken = await checkAccessTokenExp(accessToken)
    headers['Authorization'] = accessToken
  }

  headers['Content-Type'] = contentType


  //some browsers might not have useragent or proper device info
  try {
    const deviceDetector = new DeviceDetector();
    const info = deviceDetector.parse(window.navigator.userAgent);
    let edxrUserAgent = { app: 'web', appVersion: process.env.REACT_APP_VERSION, deviceName: info.device.brand,  deviceType: info.device.type, deviceOS: info.os.name, devicePlatform: info.client.name }
    headers['edxr-user-agent'] = JSON.stringify(edxrUserAgent)
  } catch {}
  

  config['headers'] = headers

  return config
}

const getConfigBlob = () => {
  let config = {
    responseType: 'blob'
  }

  let headers = {}

  const accessToken = getAccessToken()

  if (accessToken) {
    headers['Authorization'] = accessToken
  }

  config['headers'] = headers

  return config
}

// function sleep(ms) {
//   console.log('DELAYING API FOR TESTING MS: ', ms)
//   return new Promise(resolve => setTimeout(resolve, ms))
// }

// function checkAuthRedirect(error) {
//   if (error.response.status === 401) {
//     window.location = '/auth/email'
//   }

//   // if (error.message === 'Request failed with status code 401') {
//   //   window.location = '/auth/email'
//   // }
// }

function checkError(error, fnReject) {
  if (error.response) {
    if (error.response.data.error) {
      fnReject({ error: error.response.data.error, status: error.response.status })
    } else if (error.response.data) {
      fnReject({ error: error.response.data, status: error.response.status })
    } else {
      fnReject({ error: 'an error occured' })
    }
  } else if (error.request) {
    fnReject(error.request)
  } else {
    fnReject({ error: 'an error occured' })
  }
}


export const getAsync = async (server, endpoint, data) => {
  const url = `${server}/${endpoint}`
  let config = await getConfig()
  return await axios.get(url, config)
}


export const postAsync = async (server, endpoint, data) => {
  const url = `${server}/${endpoint}`
  let config = await getConfig()
  return await axios.post(url, data, config)
}

export const putAsync = async (server, endpoint, data) => {
  const url = `${server}/${endpoint}`
  let config = await getConfig()
  return await axios.put(url, data, config)
}

export const deleteAsync = async (server, endpoint) => {
  const url = `${server}/${endpoint}`
  let config = await getConfig()
  return await axios.delete(url, config)
}

/////////////////////////////////////////
/////////////////////////////////////////
/////////////////////////////////////////
/////////////////////////////////////////

export function getBlobAsync(server, endpoint) {
  return new Promise((resolve, reject) => {
    const url = `${server}/${endpoint}`
    let config = getConfigBlob()

    axios
      .get(url, config)
      .then(async response => {
        // if (process.env.REACT_APP_API_DELAY) {
        //   await sleep(process.env.REACT_APP_API_DELAY)
        // }

        resolve(response)
      })
      .catch(error => {
        console.error(error)
        checkError(error, reject)
      })
  })
}

// export function getAsync(server, endpoint) {
//   return new Promise(async (resolve, reject) => {
//     const url = `${server}/${endpoint}`

//     let config = await getConfig()

//     axios
//       .get(url, config)
//       .then(async response => {
//         // if (process.env.REACT_APP_API_DELAY) {
//         //   await sleep(process.env.REACT_APP_API_DELAY)
//         // }

//         resolve(response)
//       })
//       .catch(error => {
//         console.error(error)
//         checkAuthRedirect(error)
//         checkError(error, reject)
//       })
//   })
// }

// export function postAsync(server, endpoint, data) {
//   return new Promise(async (resolve, reject) => {
//     const url = `${server}/${endpoint}`

//     let config = await getConfig()

//     axios
//       .post(url, data, config)
//       .then(async response => {
//         // if (process.env.REACT_APP_API_DELAY) {
//         //   await sleep(process.env.REACT_APP_API_DELAY)
//         // }

//         resolve(response)
//       })
//       .catch(error => {
//         console.error(error)
//         checkAuthRedirect(error)
//         checkError(error, reject)
//       })
//   })
// }

// export function putAsync(server, endpoint, data) {
//   return new Promise(async (resolve, reject) => {
//     const url = `${server}/${endpoint}`

//     let config = await getConfig()

//     axios
//       .put(url, data, config)
//       .then(async response => {
//         // if (process.env.REACT_APP_API_DELAY) {
//         //   await sleep(process.env.REACT_APP_API_DELAY)
//         // }

//         resolve(response)
//       })
//       .catch(error => {
//         console.error(error)
//         checkAuthRedirect(error)
//         checkError(error, reject)
//       })
//   })
// }

// export function deleteAsync(server, endpoint) {
//   return new Promise(async (resolve, reject) => {
//     const url = `${server}/${endpoint}`

//     let config = await getConfig()

//     axios
//       .delete(url, config)
//       .then(async response => {
//         // if (process.env.REACT_APP_API_DELAY) {
//         //   await sleep(process.env.REACT_APP_API_DELAY)
//         // }

//         resolve(response)
//       })
//       .catch(error => {
//         console.error(error)
//         checkAuthRedirect(error)
//         checkError(error, reject)
//       })
//   })
// }
