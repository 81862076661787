import React from 'react'
import { useQueryParam } from '../shared/hooks/location'
import { useLoad } from '../shared/hooks/load'
import { useHistory } from 'react-router-dom'
import { useApiFormAuthProvider } from '../api-new/state/auth'
import { useAuthContext } from '../context/auth.context'

export const OpenAuth = () => {
  const history = useHistory()

  const authContext = useAuthContext()

  const apiFormAuthProvider = useApiFormAuthProvider()

  const token = useQueryParam('token')
  let redirect = useQueryParam('redirect')
  let redirectDoc = useQueryParam('redirect-doc')

  useLoad(async () => {
    try {
      const authToken = await apiFormAuthProvider.get(`${token}/signin`)
      authContext.setAuthToken(authToken)

      if (redirect) {
        let path = atob(redirect)
        history.push(path)
        return
      }

      if (redirectDoc) {
        window.location = atob(redirectDoc)
        return
      }

      history.push('/')
    } catch {}
  }, [])

  return <React.Fragment />
}
