import React, { useEffect } from 'react'
import { useNodeGet } from './state'
import { useParams, useRouteMatch } from 'react-router'
import { BreadcrumbView } from '../../context/breadcrumb.context'
import { IconList } from '../../shared/icon/icon'
import { CardFullHeightScrollY } from '../../shared/card/card-full-height-scroll-y'
import { Menu, MenuDelete, MenuItem } from '../../shared/menu/menu'
import { useApiListHeatmapSeries } from '../../api-new/state/heatmap'
import { IconEnter } from '../../shared/icon/icon'
import { TableAgClient } from '../../shared/table-builder/table-ag-client'
import { ColumnDateTime, ColumnDuration, ColumnMenu, ColumnText } from '../../shared/table-builder/table-columns'

export function SeriesList() {
  const { url } = useRouteMatch()
  const { nodeID, sessionID } = useParams()

  const [nodeGetActions] = useNodeGet()

  const apiListHeatmapSeries = useApiListHeatmapSeries()

  useEffect(
    () => {
      nodeGetActions.get(nodeID)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [nodeID]
  )

  const handleReady = () => apiListHeatmapSeries.get(`${sessionID}/session`)

  const handleDestroyed = () => apiListHeatmapSeries.init()

  const handleDelete = async id => {
    await apiListHeatmapSeries.delete(id)
    await apiListHeatmapSeries.get(`${sessionID}/session`)
  }

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconList} text="scenes">
        <CardFullHeightScrollY title="Sessions">
          <TableAgClient apiList={apiListHeatmapSeries} onReady={handleReady} onDestoryed={handleDestroyed}>
            <ColumnDateTime field="beganAt" header="Began At" />
            <ColumnDateTime field="endedAt" header="Ended At" />
            <ColumnDuration field="duration" beganAt="beganAt" endedAt="endedAt" />

            <ColumnText field="displayName" />

            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuItem text="view" Icon={IconEnter} to={`${url}/display/${row.id}`} show pin />
                  <MenuDelete onClick={() => handleDelete(row.id)} show />
                </Menu>}
            />
          </TableAgClient>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
