import React from 'react'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { IconUsers } from '../../../../../shared/icon/icon'
import { IconDetails } from '../../../../../shared/icon/icon'
import { Menu, MenuDelete, MenuItem } from '../../../../../shared/menu/menu'
import { CardFullHeightScrollY } from '../../../../../shared/card/card-full-height-scroll-y'
import { useApiListAuthStatus } from '../../../../../api-new/state/auth'
import { useRoleSysAuthStatus } from '../../../../../role/hook/role-sys'
import { ColumnDateTime, ColumnLink, ColumnMenu, ColumnText } from '../../../../../shared/table-builder/table-columns'
import { TableAgClient } from '../../../../../shared/table-builder/table-ag-client'

export const AuthStatusList = () => {
  const apiListAuthStatus = useApiListAuthStatus()

  const roleSysAuthStatus = useRoleSysAuthStatus()

  const handleReady = async () => await apiListAuthStatus.get()
  const handleDestroyed = () => apiListAuthStatus.init()

  const handleDelete = async refreshToken => {
    await apiListAuthStatus.delete(refreshToken)
    await apiListAuthStatus.get()
  }

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconUsers} text="AUTH STATUS">
        <CardFullHeightScrollY title="auth status">
          <TableAgClient apiList={apiListAuthStatus} onReady={handleReady} onDestoryed={handleDestroyed}>
            <ColumnText field="organization" />
            <ColumnLink field="organization" to={row => `/admin/auth-status/list/${row['organizationID']}/organization`} />
            <ColumnLink field="login" to={row => `/admin/auth-status/list/${row['loginID']}/login`} />
            <ColumnText field="ipAddress" />
            <ColumnDateTime field="expiresOn" />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuItem
                    Icon={IconDetails}
                    text="details"
                    to={`/admin/auth-status/list/${row.refreshToken}/details`}
                    show={roleSysAuthStatus.accessRead}
                    pin
                  />
                  <MenuDelete onClick={() => handleDelete(row.refreshToken)} show={roleSysAuthStatus.accessDelete} />
                </Menu>}
            />
          </TableAgClient>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
