import React from 'react'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { IconList } from '../../../../../shared/icon/icon'
import { CardFullHeightScrollY } from '../../../../../shared/card/card-full-height-scroll-y'
import { useApiListTradeshow } from '../../../../../api-new/state/content'
import { Menu, MenuPurge, MenuRestore } from '../../../../../shared/menu/menu'
import { useRoleOrgTradeshow } from '../../../../../role/hook/role-org'
import { TableAgClient } from '../../../../../shared/table-builder/table-ag-client'
import { ColumnText, ColumnMenu } from '../../../../../shared/table-builder/table-columns'

export function TradeshowRecycle() {
  const apiListTradeshow = useApiListTradeshow()

  const roleOrgTradeshow = useRoleOrgTradeshow()

  const handleReady = async () => await apiListTradeshow.get('deleted')
  const handleDestroyed = () => apiListTradeshow.init()

  const handleRestore = async tradeshow => {
    await apiListTradeshow.put(`${tradeshow.id}/restore`)
    await apiListTradeshow.get('deleted')
  }

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconList} text="recycle bin">
        <CardFullHeightScrollY title="recycle bin">
          <TableAgClient apiList={apiListTradeshow} onReady={handleReady} onDestoryed={handleDestroyed}>
            <ColumnText field="name" />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuRestore onClick={() => handleRestore(row)} show={roleOrgTradeshow.accessDelete} pin />
                  <MenuPurge to={`/org/tradeshow/list/recycle/purge/${row.id}`} show={roleOrgTradeshow.accessPurge} />
                </Menu>}
            />
          </TableAgClient>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
