import { useContext } from 'react'
import { AuthContext } from '../../../../context/auth.context'
import { useApiFormAuthProvider } from '../../../../api-new/state/auth'

export const useProviderSignin = () => {
  const authContext = useContext(AuthContext)

  const apiFormAuthProvider = useApiFormAuthProvider()

  return async (providerLink, email, redirect, redirectDoc) => {
    let authPassave = await apiFormAuthProvider.post()

    let redirectSigninURL = `${process.env.REACT_APP_SITE}/open/auth`
    let redirectCancelURL = `${process.env.REACT_APP_SITE}/auth/email`
    let redirectErrorURL = `${process.env.REACT_APP_SITE}/auth/error`

    if (redirect) {
      redirectSigninURL += `?redirect=${redirect}`
    }

    if (redirectDoc) {
      redirectSigninURL += `?redirect-doc=${redirectDoc}`
    }

    if (providerLink.signoutURL) {
      authContext.actions.setSignoutURL(providerLink.signoutURL)
    }

    // let url = `${process.env
    //   .REACT_APP_API_AUTH}/api/v1/auth-provider/${providerLink.identifier}?token=${authPassave.token}&redirect-signin-url=${redirectSigninURL}&redirect-cancel-url=${redirectCancelURL}&redirect-error-url=${redirectErrorURL}&redirect-doc=${redirectDoc}`

    let url = `${process.env
      .REACT_APP_API_AUTH}/api/v1/auth-provider/${providerLink.identifier}?token=${authPassave.token}&redirect-signin-url=${redirectSigninURL}&redirect-cancel-url=${redirectCancelURL}&redirect-error-url=${redirectErrorURL}`

    if (email) {
      url += `&email=${email}`
    }

    window.location = url
  }
}
