import React from 'react'
import { Route } from 'react-router-dom'
import { LayoutUnAuthorized } from '../../layout/unauthorized/auth/layout-auth'

export const RouteUnauthorized = () => {
  return (
    <React.Fragment>
      <Route path="/auth" component={LayoutUnAuthorized} />
    </React.Fragment>
  )
}
