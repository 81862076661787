import React from 'react'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'
import { Card } from '../../../../../../shared/card/card'
import { Form, FormSubmit, FormText } from '../../../../../../shared/form/form'
import { useApiFormNodeCourse, useApiListAsset } from '../../../../../../api-new/state/content'
import { IconEdit } from '../../../../../../shared/icon/icon'
import { useDataContext } from '../../data-context'
import { FormCourseAccessID } from '../../../../../../shared/form/form-control/form-course-access-id'
import { FormCourseMetadata } from '../../../../../../shared/form/form-control/form-course-metadata'
import { CourseDeepLink } from '../../common/model/course-deep-link'
import { NodeContentMenuFull } from '../common/node-content-menu'
import { useLoad } from '../../../../../../shared/hooks/load'
import { ThumnailAsset } from '../../common/thumbnail/thumbnail-asset'
// import { NodeTags } from '../../common/shared/node-tags'

export const NodeCourseEdit = ({ parentURL, nodeID }) => {
  const apiFormNodeCourse = useApiFormNodeCourse()
  const apiListAsset = useApiListAsset()

  const dataContext = useDataContext()

  useLoad(
    async () => {
      apiFormNodeCourse.get(nodeID)
      apiListAsset.get(`${nodeID}/node`)
    },
    [nodeID]
  )

  const handleSubmit = async data => {
    let node = await apiFormNodeCourse.put(data.id, data)
    dataContext.nodeUpdate(node)
  }

  const HeaderMenu = () => NodeContentMenuFull(parentURL, apiFormNodeCourse.form, true)

  return (
    <React.Fragment>
      <BreadcrumbView text="edit" Icon={IconEdit}>
        <CardFullHeightScrollY title="edit" HeaderMenu={HeaderMenu}>
          <Card>
            <Form state={apiFormNodeCourse.form} onSubmit={handleSubmit}>
              <FormText label="name" name="name" />
              <FormCourseAccessID label="access" name="accessID" />
              <FormCourseMetadata name="metadata" />
              <FormSubmit text="update" />
            </Form>
          </Card>

          {/* <NodeTags apiForm={apiFormNodeCourse} /> */}

          <ThumnailAsset nodeID={nodeID} />
        </CardFullHeightScrollY>
      </BreadcrumbView>
      <CourseDeepLink targetID="courseLink" nodeID={nodeID} />
    </React.Fragment>
  )
}
