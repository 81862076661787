import React from 'react'
import { useParams } from 'react-router-dom'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { Menu, MenuAdd, MenuDelete, MenuEdit } from '../../../../../shared/menu/menu'
import { CardFullHeightScrollY } from '../../../../../shared/card/card-full-height-scroll-y'
import { useApiListRoleSysPermission } from '../../../../../api-new/state/content'
import { IconList } from '../../../../../shared/icon/icon'
import { TableAgClient } from '../../../../../shared/table-builder/table-ag-client'
import { ColumnLink, ColumnMenu, ColumnText, ColumnYesNo } from '../../../../../shared/table-builder/table-columns'

export const RoleAccessListIdentifier = () => {
  const { identifier } = useParams()

  const apiListRoleSysPermission = useApiListRoleSysPermission()

  const handleReady = () => apiListRoleSysPermission.get(`${identifier}/identifier`)
  const handleDestroyed = () => apiListRoleSysPermission.init()

  const HeaderMenu = () =>
    <Menu>
      <MenuAdd text="add role permission" to={'/root/role-sys-permission/list/add'} show />
    </Menu>

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconList} text={identifier}>
        <CardFullHeightScrollY title={`role permission ${identifier}`} HeaderMenu={HeaderMenu}>
          <TableAgClient apiList={apiListRoleSysPermission} onReady={handleReady} onDestoryed={handleDestroyed}>
            <ColumnText field="identifier" name="identifier" />

            <ColumnLink field="roleName" header="role" to={row => `/root/role-sys-permission/list/role/${row.roleName}`} />

            <ColumnYesNo field="accessCreate" name="create" />
            <ColumnYesNo field="accessRead" name="read" />
            <ColumnYesNo field="accessUpdate" name="update" />
            <ColumnYesNo field="accessDelete" name="delete" />

            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuEdit to={`/root/role-sys-permission/list/edit/${row.id}`} show />
                  <MenuDelete to={`/root/role-sys-permission/list/edit/${row.id}/delete`} show />
                </Menu>}
            />
          </TableAgClient>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
