import React from 'react'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { Menu, MenuDelete } from '../../../../../shared/menu/menu'
import { CardFullHeightScrollY } from '../../../../../shared/card/card-full-height-scroll-y'
import { useApiListAuthSession } from '../../../../../api-new/state/auth'
import { ColumnDateTime, ColumnMenu, ColumnText } from '../../../../../shared/table-builder/table-columns'
import { TableAgClient } from '../../../../../shared/table-builder/table-ag-client'
import { IconAuthLogin } from '../../../../../shared/icon/icon'

export const AuthSessionList = () => {
  const apiListAuthSession = useApiListAuthSession()

  const handleReady = async () => await apiListAuthSession.get()
  const handleDestroyed = () => apiListAuthSession.init()

  const handleDelete = async authSession => {
    await apiListAuthSession.delete(authSession.id)
    await apiListAuthSession.get()
  }

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconAuthLogin} text="AUTH SESSION">
        <CardFullHeightScrollY title="auth status">
          <TableAgClient apiList={apiListAuthSession} onReady={handleReady} onDestoryed={handleDestroyed}>
            <ColumnText field="email" />
            <ColumnText field="organization" />
            <ColumnDateTime field="createdAt" header="Created" />
            <ColumnDateTime field="expiresOn" header="Expires" />
            <ColumnText field="ipAddress" header="IP" />
            <ColumnText field="ipLocation" header="Location" />
            <ColumnText field="app" />
            <ColumnText field="appVersion" />
            <ColumnText field="deviceName" />
            <ColumnText field="deviceType" />
            <ColumnText field="deviceOS" />
            <ColumnText field="devicePlatform" />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuDelete onClick={() => handleDelete(row)} show disabled={row.isCurrentSession} />
                </Menu>}
            />
          </TableAgClient>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
