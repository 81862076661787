import React from 'react'
import { Route } from 'react-router-dom'
import { LoginSession } from './content/login-session'

export const LayoutLoginSession = () => {
  return (
    <React.Fragment>
      <Route path="/org/login-session" component={LoginSession} />
    </React.Fragment>
  )
}
