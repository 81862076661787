'use client'

import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { IconAdd, IconAiDocument } from '../../../../../../shared/icon/icon'
import { useApiFormEmbedding, useApiListDocument, useEmbeddingNodeProgress } from '../../../../../../api-new/state/ai'
import { Menu, MenuDelete, MenuItem } from '../../../../../../shared/menu/menu'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'
import { useSelectFile } from '../../../../../../shared/file/select/file-select'
import { useContextUploadManager } from '../../../../../../shared/manager-upload/context/context-upload-manager'
import { UploadList } from '../../../../../../shared/manager-upload/component/upload-list'
import { Progress } from '../../../../../../shared/legacy/progress'
import { FileDrop } from '../../../../../../shared/file/file-drop'
import { TableAgClient } from '../../../../../../shared/table-builder/table-ag-client'
import { ColumnText, ColumnDateTime, ColumnMenu } from '../../../../../../shared/table-builder/table-columns'

export const AiDocumentList = ({ nodeID }) => {
  const uploadManager = useContextUploadManager()

  const apiListDocument = useApiListDocument()

  const uploadFiles = useCallback(async files => {
    let items = Object.values(files)

    let data = items.map(file => {
      return {
        nodeID: nodeID,
        name: file.name
      }
    })

    let assets = await apiListDocument.postSync(`/${nodeID}/batch`, data)

    let uploads = []

    assets.forEach(asset => {
      let file = items.find(item => item.name === asset.name)
      uploads.push({ asset, file })
    })

    uploadManager.uploadInsert(uploads)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleMatch = () => {} //apiListDocument.get(`${nodeID}/node`)

  const selectFile = useSelectFile(files => uploadFiles(files))

  useEffect(() => {
    const listener = item => apiListDocument.getID(item.asset.id)
    uploadManager.listenerAdd(listener)
    return () => uploadManager.listenerRemove(listener)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleAddDocuments = () => selectFile()

  const scanRef = useRef()

  const handleScanAll = () => scanRef.current.start()

  const handleDelete = asset => apiListDocument.delete(asset.id)

  const handleScanAllComplete = () => apiListDocument.get(`${nodeID}/node`)

  const HeaderMenu = () =>
    <Menu>
      <MenuItem Icon={IconAdd} text="add" onClick={handleAddDocuments} show />
      <MenuItem Icon={IconAiDocument} text="scan all" onClick={handleScanAll} show />
    </Menu>

  const handleDrop = files => uploadFiles(files)

  const tableRef = useRef()

  const handleReady = () => apiListDocument.get(`${nodeID}/node`)
  const handleDestroyed = () => apiListDocument.init()

  const apiFormEmbedding = useApiFormEmbedding()

  const handleScanAsset = async asset => {
    await apiFormEmbedding.postArg(`/${asset.id}/asset`)
    await apiListDocument.getID(asset.id)
  }

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconAiDocument} text="documents" onMatch={handleMatch}>
        <CardFullHeightScrollY title="AI Documents" HeaderMenu={HeaderMenu}>
          <UploadList />
          <ScanDocuments ref={scanRef} nodeID={nodeID} onComplete={handleScanAllComplete} />
          <FileDrop allowedFileTypes={['text/markdown', 'application/pdf']} onDrop={handleDrop}>
            <TableAgClient ref={tableRef} apiList={apiListDocument} hideFloatingFilter onReady={handleReady} onDestoryed={handleDestroyed}>
              <ColumnText field="name" />
              <ColumnDateTime field="embedded on" selector={row => JSON.parse(row.metadata).embeddingOn} />
              <ColumnMenu
                menu={row =>
                  <Menu small>
                    <MenuDelete show onClick={() => handleDelete(row)} />
                    <MenuItem Icon={IconAiDocument} text="Scan" show onClick={() => handleScanAsset(row)} />
                  </Menu>}
              />
            </TableAgClient>
          </FileDrop>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}

const ScanDocuments = forwardRef(({ nodeID, onComplete }, ref) => {
  const [progress, updateProgress] = useState(null)

  const handleProgress = progress => updateProgress(progress)

  const handleComplete = () => {
    updateProgress(null)
    onComplete && onComplete()
  }

  const [scanStart] = useEmbeddingNodeProgress(handleProgress, handleComplete)

  useImperativeHandle(
    ref,
    () => ({
      start: () => scanStart({ nodeID })
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return (
    <React.Fragment>
      {progress &&
        <div>
          <Progress current={progress.progressCount} length={progress.progressLength} />
        </div>}
    </React.Fragment>
  )
})
