import React, { forwardRef, useMemo, useRef, useState } from 'react'
import { Search } from '../component/filter'
import { TableAG } from './table-ag'
import { getColumns } from './table-columns'
import { useGetQueryValues, useSetQueryValues } from './hooks'
import { QueryItems } from './component/table-query-items'

export const TableAgInfinite = forwardRef(({ children, onGetRows, onRowClicked, onDestoryed }, ref) => {
  const tableRef = useRef()

  let columns = useMemo(() => getColumns(children), [children])

  const [search, updateSearch] = useState('')
  const [loading, updateLoading] = useState(true)

  const getQueryValues = useGetQueryValues()
  const setQueryValues = useSetQueryValues()

  const handleReady = async agGrid => {
    let datasource = {
      getRows: async params => {
        let sortCol = ''
        let sortDir = ''

        if (params.sortModel.length) {
          sortCol = params.sortModel[0].colId
          sortDir = params.sortModel[0].sort
        }

        const queryValues = getQueryValues()

        setQueryValues(undefined, undefined, sortCol, sortDir)

        if (onGetRows) {
          updateLoading(true)
          let result = await onGetRows(params.startRow, params.endRow, queryValues.search, sortCol, sortDir)
          params.successCallback(result.data, result.count)
          updateLoading(false)

          if (queryValues.index !== null) {
            tableRef.current?.api?.ensureIndexVisible(queryValues.index, 'top')
          }
        }
      }
    }

    const queryValues = getQueryValues()

    if (queryValues.search) {
      updateSearch(queryValues.search)
    }

    if (queryValues.sortCol && queryValues.sortDir) {
      tableRef.current?.api?.applyColumnState({
        state: [
          {
            colId: queryValues.sortCol,
            sort: queryValues.sortDir
          }
        ]
      })
    }

    agGrid.api.setGridOption('datasource', datasource)
  }

  const handleSearch = search => {
    setQueryValues(search, 0, undefined, undefined)

    tableRef.current?.api?.purgeInfiniteCache()
    tableRef.current?.api?.paginationGoToPage(0)
  }

  const handleRefresh = () => {
    tableRef.current?.api?.purgeInfiniteCache()
    tableRef.current?.api?.paginationGoToPage(0)
  }

  const handleClearState = () => tableRef.current?.api?.resetColumnState()

  const handleClearSearch = () => {
    updateSearch('')
    setQueryValues('', 0, undefined, undefined)
    tableRef.current?.api?.purgeInfiniteCache()
    tableRef.current?.api?.paginationGoToPage(0)
  }

  const handleScrollVertical = index => setQueryValues(undefined, index, undefined, undefined)

  return (
    <div className="d-flex flex-column h-100">
      <div>
        <QueryItems onClearSort={handleClearState} onClearSearch={handleClearSearch}  />
      </div>
      <div>
        <Search value={search} onChange={updateSearch} onSearch={handleSearch}  />
      </div>
      <div className="flex-fill">
        <TableAG
          ref={tableRef}
          funcRef={ref}
          columns={columns}
          loading={loading}
          rowModelType="infinite"
          // cacheBlockSize={1000}
          cacheBlockSize={200}
          maxBlocksInCache={10}
          cacheOverflowSize={10}
          infiniteInitialRowCount={10}
          onReady={handleReady}
          onRefresh={handleRefresh}
          onRowClicked={onRowClicked}
          onScrollVertical={handleScrollVertical}
          onDestoryed={onDestoryed}
        />
      </div>
    </div>
  )
})
