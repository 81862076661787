import React, { useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useAuthContext } from '../../../../context/auth.context'
import { NavLink, PositionCenter, TitleMain, TitleSub } from './component'
import { Form, FormText, FormPassword, FormSubmit } from '../../../../shared/form/form'
import { useQueryParam } from '../../../../shared/hooks/location'
import { LoginDeviceModal } from './login-device-modal'
import { ModalLinkShow } from '../../../../shared/component/modal-dialog'
import { IconLoginDevice } from '../../../../shared/icon/icon'
import { ButtonPrimary } from '../../../../shared/component/button'
import { AlertErrorMessage } from '../../../../shared/alert/alert-error'
import { useApiFormAuthEmail, useApiListAuthProviderLink } from '../../../../api-new/state/auth'
import { useBuildRouteArgs, useLoginRedirect } from '../hooks/redirect'

export const LoginEmail = () => {
  const history = useHistory()

  const apiFormAuthEmail = useApiFormAuthEmail()
  const apiListAuthProviderLink = useApiListAuthProviderLink()

  const authContext = useAuthContext()

  const loginRedirect = useLoginRedirect()

  const [showPassword, updateShowPassword] = useState(false)
  const [email, updateEmail] = useState('')

  const validEmail = useMemo(() => !!email, [email])

  let redirect = useQueryParam('redirect')
  let redirectDoc = useQueryParam('redirect-doc')

  const buildRouteArgs = useBuildRouteArgs()

  const handleNext = async () => {

    if (!email) {
      return
    }

    try {
      let emailParts = email.split('@')

      let emailURL = emailParts.length === 2 ? emailParts[1] : ''

      if (emailURL) {
        const providers = await apiListAuthProviderLink.get(emailURL)

        if (providers.length) {
          let {path} = buildRouteArgs('/auth/providers', redirect, redirectDoc, email)
          history.push(path)
          return
        }
      }

      var emailExists = await apiFormAuthEmail.get(`${email}/exists`)

      if (!emailExists) {
        history.push('/public/register')
        return
      }

      updateShowPassword(true)
    } catch {}
  }

  const handleSubmit = async data => {
    if (!data.password) {
      handleNext()
      return
    }

    try {
      let authToken = await apiFormAuthEmail.post(data)
      authContext.setAuthToken (authToken)
      loginRedirect()
    } catch {}
  }

  return (
    <React.Fragment>
      <PositionCenter>
        <AlertErrorMessage error={apiFormAuthEmail.error} />

        <TitleMain value="Login" />
        <TitleSub value="Sign in to your account" />

        <Form onSubmit={handleSubmit}>
          <FormText id="email" name="email" placeholder="email/login" required shouldDirty autoFocus={true} onChange={updateEmail} onEnter={handleNext} />

          {showPassword &&
            <div>
              <FormPassword name="password" required autoFocus shouldDirty />
              <div className="text-end">
                <NavLink text="Forgot Password?" to="/auth/password-recover/request" />
              </div>
            </div>}

          {showPassword &&
            <div className="my-3">
              <FormSubmit text="sign in" />
            </div>}
        </Form>

        {!showPassword &&
          <div className="my-3">
            <ButtonPrimary text="Next" disabled={!validEmail} onClick={handleNext} />
          </div>}

        <div>
          <div className="row justify-content-center g-2">
            <div className="col-7 text-center m-1">
              <span>-Or-</span>
            </div>
          </div>
          <div className="row justify-content-center g-2">
            <div className="col-8 col-xl-8 col-xxl-8">
              <ModalLinkShow className="btn btn-light border w-100 text-start" target="device" Icon={IconLoginDevice} text="Login from another device" />
            </div>
          </div>
        </div>
      </PositionCenter>

      <LoginDeviceModal target="device" />
    </React.Fragment>
  )
}
