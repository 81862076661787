import { useMemo, useReducer } from 'react'
import { deleteAsync, getAsync, postAsync, putAsync } from '../shared/api.service'

export const types = {
  INIT: 'INIT',
  ERROR: 'ERROR',

  POST_SYNC: 'POST_SYNC',
  POST_SYNC_SUCCESS: 'POST_SYNC_SUCCESS',

  LOAD: 'LOAD',
  LOAD_SUCCESS: 'LOAD_SUCCESS',

  SUBMIT: 'SUBMIT',
  GET_ID: 'GET_ID',
  GET_ID_SUCCESS: 'GET_ID_SUCCESS',
  POST_SUCCESS: 'POST_SUCCESS',
  PUT_SUCCESS: 'PUT_SUCCESS',
  DELETE_SUCCESS: 'DELETE_SUCCESS',

  INSERT: 'INSERT',
  REMOVE: 'REMOVE'
}

const initialState = {
  items: [],
  loading: false,
  loaded: false,
  submitting: false,
  error: undefined
}

const reducer = (state, action) => {
  switch (action.type) {
    case types.INIT:
      return { ...initialState }
    case types.ERROR:
      return { ...state, loading: false, submitting: false, error: action.payload }

    case types.POST_SYNC:
      return { ...state, loaded: false, loading: false, error: undefined }
    case types.POST_SYNC_SUCCESS:
      action.payload.forEach(item => {
        let index = state.items.findIndex(i => i.id === item.id)
        if (index === -1) {
          state.items.push(item)
        } else {
          state.items[index] = item
        }
      })
      return { ...state, loaded: false, loading: false, error: undefined }
    case types.LOAD:
      return { ...state, loaded: false, loading: true, error: undefined }
    case types.LOAD_SUCCESS:
      return { ...state, items: action.payload, loaded: true, loading: false, error: undefined }
    case types.SUBMIT:
      return { ...state, submitting: true, error: undefined }

    case types.GET_ID:
      return { ...state, submitting: true, error: undefined }
    case types.GET_ID_SUCCESS:
      let indexGetID = state.items.findIndex(i => i.id === action.payload.id)
      state.items[indexGetID] = action.payload
      return { ...state, submitting: false, error: undefined }
    case types.POST_SUCCESS:
      if (Array.isArray(action.payload)) {
        return { ...state, items: [...state.items, ...action.payload], submitting: false, error: undefined }
      } else {
        return { ...state, items: [...state.items, action.payload], submitting: false, error: undefined }
      }
    case types.PUT_SUCCESS:
      let indexPut = state.items.indexOf(action.payload)
      state.items[indexPut] = action.payload
      return { ...state, items: [...state.items], submitting: false, error: undefined }
    case types.DELETE_SUCCESS:
      let indexDelete = state.items.findIndex(item => item.id === action.payload)
      state.items.splice(indexDelete, 1)
      return { ...state, items: [...state.items], submitting: false, error: undefined }

    case types.INSERT:
      let indexInsert = state.items.findIndex(i => i.id === action.payload.id)
      state.items[indexInsert] = action.payload
      return { ...state, items: [...state.items], submitting: false, error: undefined }

    case types.REMOVE:
      let removeIndex = state.items.indexOf(action.payload)
      if (removeIndex >= 0) {
        state.items.splice(removeIndex, 1)
      }
      return { ...state, items: [...state.items], submitting: false, error: undefined }
    default:
      return state
  }
}

export const useApiList = (server, endpoint) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const actions = useMemo(() => {
    return {
      init: () => initAction(dispatch)(),
      get: arg => getAction(server, endpoint, dispatch)(arg),
      getID: arg => getIDAction(server, endpoint, dispatch)(arg),
      post: data => postAction(server, endpoint, dispatch)(data),
      postArg: (arg, data) => postArgAction(server, endpoint, arg, dispatch)(data),
      postSync: (arg, data) => postSyncAction(server, endpoint, arg, dispatch)(data),
      put: (arg, data) => putAction(server, endpoint, dispatch)(arg, data),
      delete: arg => deleteAction(server, endpoint, dispatch)(arg),
      insert: item => actionInsert(dispatch)(item),
      remove: item => removeAction(dispatch)(item)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return useMemo(
    () => {
      return { ...state, ...actions }
    },
    [state, actions]
  )
}

const initAction = dispatch => () => {
  dispatch({ type: types.INIT })
}

const getAction = (server, endpoint, dispatch) => async arg => {
  try {
    if (arg) {
      endpoint = `${endpoint}/${arg}`
    }

    dispatch({ type: types.LOAD })
    const result = await getAsync(server, endpoint)
    dispatch({ type: types.LOAD_SUCCESS, payload: result.data })
    return result.data
  } catch (exp) {
    dispatch({ type: types.ERROR, payload: exp.response?.data || null })
    console.error(exp.response)
    throw exp.response
  }
}

const getIDAction = (server, endpoint, dispatch) => async arg => {
  try {
    if (arg) {
      endpoint = `${endpoint}/${arg}`
    }

    dispatch({ type: types.GET_ID })
    const result = await getAsync(server, endpoint)
    dispatch({ type: types.GET_ID_SUCCESS, payload: result.data })
    return result.data
  } catch (exp) {
    dispatch({ type: types.ERROR, payload: exp.response?.data || null })
    console.error(exp.response)
    throw exp.response
  }
}

const postAction = (server, endpoint, dispatch) => async data => {
  try {
    dispatch({ type: types.SUBMIT })
    const result = await postAsync(server, endpoint, data)
    dispatch({ type: types.POST_SUCCESS, payload: result.data })
    return result.data
  } catch (exp) {
    dispatch({ type: types.ERROR, payload: exp.response?.data || null })
    console.error(exp.response)
    throw exp.response
  }
}

const postArgAction = (server, endpoint, arg, dispatch) => async data => {
  try {
    dispatch({ type: types.SUBMIT })
    const result = await postAsync(server, endpoint + arg, data)
    dispatch({ type: types.POST_SUCCESS, payload: result.data })
    return result.data
  } catch (exp) {
    dispatch({ type: types.ERROR, payload: exp.response?.data || null })
    console.error(exp.response)
    throw exp.response
  }
}

const postSyncAction = (server, endpoint, arg, dispatch) => async data => {
  try {
    dispatch({ type: types.POST_SYNC })
    const result = await postAsync(server, endpoint + arg, data)
    dispatch({ type: types.POST_SYNC_SUCCESS, payload: result.data })
    return result.data
  } catch (exp) {
    dispatch({ type: types.ERROR, payload: exp.response?.data || null })
    console.error(exp.response)
    throw exp.response
  }
}

const putAction = (server, endpoint, dispatch) => async (arg, data) => {
  try {
    endpoint = `${endpoint}/${arg}`
    dispatch({ type: types.SUBMIT })
    const result = await putAsync(server, endpoint, data)
    dispatch({ type: types.PUT_SUCCESS, payload: result.data })
    return result.data
  } catch (exp) {
    dispatch({ type: types.ERROR, payload: exp.response?.data || null })
    console.error(exp.response)
    throw exp.response
  }
}

const deleteAction = (server, endpoint, dispatch) => async arg => {
  try {
    endpoint = `${endpoint}/${arg}`
    dispatch({ type: types.SUBMIT })
    const result = await deleteAsync(server, endpoint)
    dispatch({ type: types.DELETE_SUCCESS, payload: arg })
    return result
  } catch (exp) {
    dispatch({ type: types.ERROR, payload: exp.response?.data || null })
    console.error(exp.response)
    throw exp.response
}
}

const actionInsert = dispatch => item => {
  dispatch({ type: types.INSERT, payload: item })
}

const removeAction = dispatch => async item => {
  dispatch({ type: types.REMOVE, payload: item })
}
