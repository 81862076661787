import React from 'react'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { IconList } from '../../../../../../shared/icon/icon'
import { useParamsInt } from '../../../../../../shared/hooks/location'
import { Menu, MenuActivityLog, MenuAdd, MenuDelete, MenuEdit, MenuItem, MenuRecycle } from '../../../../../../shared/menu/menu'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'
import { useApiListAppStore } from '../../../../../../api-new/state/config'
import { useRoleSysApp } from '../../../../../../role/hook/role-sys'
import { TableAgClient } from '../../../../../../shared/table-builder/table-ag-client'
import { ColumnText, ColumnMenu } from '../../../../../../shared/table-builder/table-columns'

export const AppStoreList = () => {
  const { appID } = useParamsInt()

  const apiListAppStore = useApiListAppStore()

  const roleSysApp = useRoleSysApp()

  const handleReady = () => apiListAppStore.get(`${appID}/app`)
  const handleDestroyed = () => apiListAppStore.init()

  const HeaderMenu = () =>
    <Menu>
      <MenuAdd text="add" to={`/admin/app/edit/${appID}/store/add`} show={roleSysApp.accessCreate} />
      <MenuActivityLog to={`/admin/app/edit/${appID}/store/activity-all`} show />
      <MenuRecycle to={`/admin/app/edit/${appID}/store/recycle`} show={roleSysApp.accessDelete} />
    </Menu>

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconList} text="stores">
        <CardFullHeightScrollY title="stores" HeaderMenu={HeaderMenu}>
          <TableAgClient apiList={apiListAppStore} onReady={handleReady} onDestoryed={handleDestroyed}>
            <ColumnText field="name" />
            <ColumnText field="key" />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuEdit to={`/admin/app/edit/${appID}/store/edit/${row.id}`} show={roleSysApp.accessUpdate} />
                  <MenuItem Icon={IconList} text="versions" to={`/admin/app/edit/${appID}/store/edit/${row.id}/version`} show />
                  <MenuDelete to={`/admin/app/edit/${appID}/store/edit/${row.id}/delete`} show={roleSysApp.accessDelete} />
                  <MenuActivityLog to={`/admin/app/edit/${appID}/store/activity-id/${row.id}`} show />
                </Menu>}
            />
          </TableAgClient>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
