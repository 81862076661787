import React, { useMemo } from 'react'
import { useAuthContext } from '../../context/auth.context'
import { useHistory } from '../../../node_modules/react-router-dom/cjs/react-router-dom.min'
import { useApiFormWhiteLabel } from '../../api-new/state/content'
import { useLoad } from '../../shared/hooks/load'

const logoPath = '/org/dashboard'

export const HeaderLogo = ({ small }) => {
  const history = useHistory()

  const authContext = useAuthContext()

  const apiFormWhiteLabel = useApiFormWhiteLabel()

  useLoad(
    async () => {
      try {
        if (authContext.state.organizationID) {
          await apiFormWhiteLabel.get(authContext.state.organizationID)
        }
      } catch {}
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [authContext.state]
  )

  const logo = useMemo(() => (apiFormWhiteLabel.form.logoURL ? apiFormWhiteLabel.form.logoURL : '/EDXR-Logo.png'), [apiFormWhiteLabel.form])

  const handleClick = () => history.push(logoPath)

  return (
    <React.Fragment>
      <div className="d-inline" onClick={handleClick}>
        <img className={`edxr-pointer ${small ? 'logo-sm' : 'logo'}`} src={logo} alt="header logo" />
      </div>
    </React.Fragment>
  )
}
