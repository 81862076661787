import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useParamsInt } from '../../../../../shared/hooks/location'
import { useNotificationAlertError } from '../../../../../context/notification/type-alert'
import { useApiFormAnalyticTopic, useApiListAnalyticParent } from '../../../../../api-new/state/analytics'
import { useLoad } from '../../../../../shared/hooks/load'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { IconAdd } from '../../../../../shared/icon/icon'
import { CardFullHeightScrollY } from '../../../../../shared/card/card-full-height-scroll-y'
import { Card } from '../../../../../shared/card/card'
import { Form, FormHiddenNumber, FormHiddenText, FormSubmitCancel, FormText, FormTextArea } from '../../../../../shared/form/form'

export const AnalyticCourseTopicAdd = () => {
  const { nodeID } = useParamsInt()

  const history = useHistory()

  const alert = useNotificationAlertError()

  const apiListAnalyticParent = useApiListAnalyticParent()
  const apiFormAnalyticTopic = useApiFormAnalyticTopic()

  const [analyticParentID, updateAnalyticParentID] = useState()

  useLoad(async () => {
    let analyticParents = await apiListAnalyticParent.get()
    let analyticParentCourse = analyticParents.find(i => i.name === 'course')
    if (analyticParentCourse) {
      updateAnalyticParentID(analyticParentCourse.id)
      alert.hide()
    } else {
      alert.show(`analytic parent: "course" not found`, false)
    }
  }, [])

  const handleSubmit = async data => {
    await apiFormAnalyticTopic.post(data)
    history.goBack()
  }

  const handleClose = () => history.goBack()

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconAdd} text="Add">
        <CardFullHeightScrollY title="Add Topic">
          <Card>
            <Form state={apiFormAnalyticTopic.form} disabled={!analyticParentID} onSubmit={handleSubmit}>
              <FormHiddenNumber name="analyticParentID" value={analyticParentID} />
              <FormHiddenText name="parentID" value={nodeID} />
              <FormText label="name" name="name" autoFocus />
              <FormTextArea label="description" name="description" small />
              <FormSubmitCancel onCancel={handleClose} />
            </Form>
          </Card>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
