import React, { useMemo } from 'react'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { Menu, MenuDelete, MenuItem } from '../../../../../shared/menu/menu'
import { CardFullHeightScrollY } from '../../../../../shared/card/card-full-height-scroll-y'
import { IconAuthLogin } from '../../../../../shared/icon/icon'
import { useApiListAuthSession } from '../../../../../api-new/state/auth'
import { useParamsInt } from '../../../../../shared/hooks/location'
import { TableAgClient } from '../../../../../shared/table-builder/table-ag-client'
import { ColumnDateTime, ColumnMenu, ColumnText, ColumnYesNo } from '../../../../../shared/table-builder/table-columns'
import { useRouteMatch } from '../../../../../../node_modules/react-router-dom/cjs/react-router-dom.min'
import { useAuthContext } from '../../../../../context/auth.context'
import { HeaderMenu } from './common/header-menu'

export const UserLoginAuthSession = () => {
  const { userID } = useParamsInt()
  const { url } = useRouteMatch()

  const authContext = useAuthContext()

  let isCurrentUser = useMemo(() => authContext.state.userID === userID, [authContext, userID])

  const apiListAuthSession = useApiListAuthSession()

  const handleReady = async () => await apiListAuthSession.get(`${userID}/user`)
  const handleDestroyed = () => apiListAuthSession.init()

  const handleDelete = async session => {
    await apiListAuthSession.delete(session.id)
    await apiListAuthSession.get(`${userID}/user`)
  }

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconAuthLogin} text="Auth Session">
        <CardFullHeightScrollY title="Auth Session" HeaderMenu={HeaderMenu}>
          <TableAgClient apiList={apiListAuthSession} onReady={handleReady} onDestoryed={handleDestroyed}>
            {isCurrentUser && <ColumnYesNo field="isCurrentSession" header="Current Session" />}
            <ColumnDateTime field="createdAt" header="Created" />
            <ColumnDateTime field="expiresOn" header="Expires" />
            <ColumnText field="ipAddress" header="IP" />
            <ColumnText field="ipLocation" header="Location" />
            <ColumnText field="app" />
            <ColumnText field="appVersion" />
            <ColumnText field="deviceName" />
            <ColumnText field="deviceType" />
            <ColumnText field="deviceOS" />
            <ColumnText field="devicePlatform" />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuItem text="auth history" Icon={IconAuthLogin} to={`${url}/auth-history/${row.id}`} onClick={() => handleDelete(row)} show />
                  <MenuDelete onClick={() => handleDelete(row)} show disabled={row.isCurrentSession} />
                </Menu>}
            />
          </TableAgClient>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
