import React from 'react'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { Menu, MenuAdd, MenuDelete, MenuEdit } from '../../../../../shared/menu/menu'
import { CardFullHeightScrollY } from '../../../../../shared/card/card-full-height-scroll-y'
import { useApiListRoleSysPermission } from '../../../../../api-new/state/content'
import { IconRole } from '../../../../../shared/icon/icon'
import { TableAgClient } from '../../../../../shared/table-builder/table-ag-client'
import { ColumnLink, ColumnMenu, ColumnYesNo } from '../../../../../shared/table-builder/table-columns'

export const RoleSysPermissionList = () => {
  const apiListRoleSysPermission = useApiListRoleSysPermission()

  const handleReady = () => apiListRoleSysPermission.get()
  const handleDestroyed = () => apiListRoleSysPermission.init()

  const HeaderMenu = () =>
    <Menu>
      <MenuAdd text="add role permission" to={'/root/role-sys-permission/list/add'} show />
    </Menu>

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconRole} text="ROLE PERMISSION">
        <CardFullHeightScrollY title="role permission" HeaderMenu={HeaderMenu}>
          <TableAgClient apiList={apiListRoleSysPermission} onReady={handleReady} onDestoryed={handleDestroyed}>
            <ColumnLink field="roleName" header="role" to={row => `/root/role-sys-permission/list/role/${row.roleName}`} />

            <ColumnLink field="identifier" header="scope" to={row => `/root/role-sys-permission/list/identifier/${row.identifier}`} />

            <ColumnYesNo field="accessCreate" header="create" />
            <ColumnYesNo field="accessRead" header="read" />
            <ColumnYesNo field="accessUpdate" header="update" />
            <ColumnYesNo field="accessDelete" header="delete" />

            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuEdit to={`/root/role-sys-permission/list/edit/${row.id}`} show />
                  <MenuDelete to={`/root/role-sys-permission/list/edit/${row.id}/delete`} show />
                </Menu>}
            />
          </TableAgClient>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
