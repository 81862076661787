import React from 'react'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { IconApp, IconList } from '../../../../../../shared/icon/icon'
import { Menu, MenuActivityLog, MenuAdd, MenuDelete, MenuEdit, MenuItem, MenuRecycle } from '../../../../../../shared/menu/menu'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'
import { useApiListApp } from '../../../../../../api-new/state/config'
import { useRoleSysApp } from '../../../../../../role/hook/role-sys'
import { TableAgClient } from '../../../../../../shared/table-builder/table-ag-client'
import { ColumnMenu, ColumnText } from '../../../../../../shared/table-builder/table-columns'

export const AppList = () => {
  const apiListApp = useApiListApp()

  const roleSysApp = useRoleSysApp()

  const handleReady = () => apiListApp.get()
  const handleDestroyed = () => apiListApp.init()

  const HeaderMenu = () =>
    <Menu>
      <MenuAdd text="add" to="/admin/app/add" show={roleSysApp.accessCreate} />
      <MenuActivityLog to="/admin/app/list/activity-all" show />
      <MenuRecycle to="/admin/app/recycle" show={roleSysApp.accessDelete} />
    </Menu>

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconApp} text="APPS">
        <CardFullHeightScrollY title="apps" HeaderMenu={HeaderMenu}>
          <TableAgClient apiList={apiListApp} onReady={handleReady} onDestoryed={handleDestroyed}>
            <ColumnText field="name" />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuEdit to={`/admin/app/edit/${row.id}`} show={roleSysApp.accessUpdate} />
                  <MenuItem Icon={IconList} text="stores" to={`/admin/app/edit/${row.id}/store`} show />
                  <MenuDelete to={`/admin/app/edit/${row.id}/delete`} show={roleSysApp.accessDelete} />
                  <MenuActivityLog to={`/admin/app/list/activity-id/${row.id}`} show />
                </Menu>}
            />
          </TableAgClient>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
