import React, { useContext, useEffect } from 'react'
import { Route } from 'react-router-dom'
import { AuthContext } from '../../context/auth.context'
import { useQueryParam } from '../../shared/hooks/location'
import { useDocAuthRedirect } from '../../layout/unauthorized/auth/hooks/doc'
import { useBuildRouteRedirectDoc } from '../../layout/unauthorized/auth/hooks/redirect'

const DocAuth = () => {
  const authContext = useContext(AuthContext)

  let redirectDoc = useQueryParam('redirect-doc')

  const docAuthRedirect = useDocAuthRedirect()

  const buildRouteRedirectDoc = useBuildRouteRedirectDoc()

  useEffect(
    () => {
      if (authContext.state.isAuth) {
        if (redirectDoc) {
          docAuthRedirect(redirectDoc)
        } else {
          window.location = process.env.REACT_APP_EDXR_DOCS
        }
      } else {
        let path = buildRouteRedirectDoc('/auth/email')
        window.location = path
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [redirectDoc]
  )

  return <React.Fragment />
}

export const RouteDoc = () => {
  return (
    <React.Fragment>
      <Route path="/doc" component={DocAuth} />
    </React.Fragment>
  )
}
