import React from 'react'
import { useApiFormChunkEventLog } from '../../../../../../api-new/state/content'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { useParamsInt } from '../../../../../../shared/hooks/location'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'
import { Menu, MenuItem } from '../../../../../../shared/menu/menu'
import { useHistory } from '../../../../../../../node_modules/react-router-dom/cjs/react-router-dom.min'
import { DateTime, Display, Text, TextArea } from '../display/display'
import { IconEdit } from '../../../../../../shared/icon/icon'

export function LogEventDetails() {
  const history = useHistory()

  const { logEventID } = useParamsInt()

  const apiFormChunkEventLog = useApiFormChunkEventLog()

  const handlePath = async () => await apiFormChunkEventLog.get(logEventID)

  const handleDelete = async () => {
    await apiFormChunkEventLog.delete(logEventID)
    history.goBack()
  }

  const HeaderMenu = () =>
    <Menu>
      <MenuItem text="delete" onClick={handleDelete} show />
    </Menu>

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconEdit} text="edit" onPath={handlePath}>
        <CardFullHeightScrollY title="event" HeaderMenu={HeaderMenu}>
          <Display title="details">
            <DateTime label="occured" value={apiFormChunkEventLog.form.createdAt} />
            <Text label="sessionID" value={apiFormChunkEventLog.form.sessionID} />
            <Text label="organization" value={apiFormChunkEventLog.form.organization} />
            <Text label="email" value={apiFormChunkEventLog.form.email} />
            <Text label="subject" value={apiFormChunkEventLog.form.subject} />
            <Text label="path" value={apiFormChunkEventLog.form.path} />
            <Text label="method" value={apiFormChunkEventLog.form.method} />
            <Text label="action" value={apiFormChunkEventLog.form.action} />
            <Text label="ipAddress" value={apiFormChunkEventLog.form.ipAddress} />
            <TextArea label="headers" value={apiFormChunkEventLog.form.headers} />
            <TextArea label="body" value={apiFormChunkEventLog.form.body} />
          </Display>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
