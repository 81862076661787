import React from 'react'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { Menu, MenuItem } from '../../../../../shared/menu/menu'
import { CardFullHeightScrollY } from '../../../../../shared/card/card-full-height-scroll-y'
import { useApiListSysAdmin } from '../../../../../api-new/state/content'
import { IconPromote } from '../../../../../shared/icon/icon'
import { TableAgClient } from '../../../../../shared/table-builder/table-ag-client'
import { ColumnMenu, ColumnText } from '../../../../../shared/table-builder/table-columns'

export function SysAdminPromote() {
  const apiListSysAdmin = useApiListSysAdmin()

  const handleReady = async () => await apiListSysAdmin.get('non-admin')
  const handleDestroyed = () => apiListSysAdmin.init()

  const handlePromote = async item => {
    await apiListSysAdmin.put(`${item.id}/promote`)
    await apiListSysAdmin.get('non-admin')
  }

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconPromote} text="promote">
        <CardFullHeightScrollY title="Logins">
          <TableAgClient apiList={apiListSysAdmin} onReady={handleReady} onDestoryed={handleDestroyed}>
            <ColumnText field="email" />
            <ColumnText field="firstName" />
            <ColumnText field="lastName" />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuItem Icon={IconPromote} text="promote" onClick={() => handlePromote(row)} show />
                </Menu>}
            />
          </TableAgClient>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
