import React from 'react'
import ContentWizard from './group-edit/widget-content'
import UserWizard from './group-edit/widget-user'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { Form, FormText, FormSubmitBack } from '../../../../../shared/form/form'
import { useApiFormGroup } from '../../../../../api-new/state/content'
import { useParamsInt } from '../../../../../shared/hooks/location'
import { Card } from '../../../../../shared/card/card'
import { TileBody, TileColumn11112 } from '../../../../../shared/tile/tile-column-21111'
import { IconContent, IconEdit, IconUser } from '../../../../../shared/icon/icon'
import { Menu, MenuActivityLog, MenuDelete, MenuItem } from '../../../../../shared/menu/menu'
import { CardFullHeightScrollY } from '../../../../../shared/card/card-full-height-scroll-y'

export function GroupEdit() {
  const { groupID } = useParamsInt()

  const apiFormGroup = useApiFormGroup()

  const handlePath = async () => await apiFormGroup.get(groupID)
  const handleSubmit = async data => await apiFormGroup.put(groupID, data)
  const handleRefresh = async () => await apiFormGroup.get(groupID)

  const HeaderMenu = () =>
    <Menu>
      <MenuItem Icon={IconContent} text="contents" to={`/org/group/list/edit/${groupID}/content`} show />
      <MenuItem Icon={IconUser} text="users" to={`/org/group/list/edit/${groupID}/user`} show />
      <MenuDelete to={`/org/group/list/edit/${groupID}/delete`} show />
      <MenuActivityLog to={`/org/group/list/edit/${groupID}/activity-id/${groupID}`} show />
    </Menu>

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconEdit} text={apiFormGroup.form.name} onPath={handlePath}>
        <CardFullHeightScrollY title={apiFormGroup.form.name} HeaderMenu={HeaderMenu}>
          <TileBody>
            <TileColumn11112>
              <Card title="Group Edit">
                <Form state={apiFormGroup.form} onSubmit={handleSubmit} onRefresh={handleRefresh}>
                  <FormText label="name" name="name" autoFocus />
                  <FormSubmitBack text="update group" />
                </Form>
              </Card>
            </TileColumn11112>
            <TileColumn11112>
              <ContentWizard groupID={groupID} />
              <UserWizard groupID={groupID} />
            </TileColumn11112>
          </TileBody>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
