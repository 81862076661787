import React, { useCallback, useMemo, useRef } from 'react'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { BadgeSecondary, BadgeSuccess } from '../../../../../shared/component/badge'
import {
  Menu,
  MenuActivityLog,
  MenuAdd,
  MenuDelete,
  MenuEdit,
  MenuExportCsv,
  MenuExportExcel,
  MenuItem,
  MenuRecycle,
  MenuTableSettingToggle
} from '../../../../../shared/menu/menu'
import { CardFullHeightScrollY } from '../../../../../shared/card/card-full-height-scroll-y'
import { IconAnalytic, IconAssessment, IconContent, IconGroup, IconUsers } from '../../../../../shared/icon/icon'
import { useApiListUserLogin } from '../../../../../api-new/state/content'
import { useRoleOrgGroupUser, useRoleOrgNodeUser, useRoleOrgUser } from '../../../../../role/hook/role-org'
import { TableAgClient } from '../../../../../shared/table-builder/table-ag-client'
import { ColumnDateTime, ColumnMenu, ColumnSelector, ColumnText } from '../../../../../shared/table-builder/table-columns'
import moment from 'moment'

const BadgeStatus = ({ userLogin }) => {
  let value = useMemo(
    () => {
      if (userLogin.invitePending) return <BadgeSecondary value="Invited" />
      else return <BadgeSuccess value="Registered" />
    },
    [userLogin]
  )

  return (
    <React.Fragment>
      {value}
    </React.Fragment>
  )
}

var second = 1000
var minute = second * 60
var hour = minute * 60
var day = hour * 24
var day7 = day * 7
var month = day * 30
var month6 = 6 * month
var year = month * 12

const lastActive = userLogin => {
  if (userLogin.invitePending) {
    return 'Never'
  }

  if (userLogin.lastActive) {
    let now = moment()
    let auth = moment(userLogin.lastActive)
    let diff = now.diff(auth)

    if (diff > year) {
      return 'Over a Year'
    } else if (diff > month6) {
      return 'Past Year'
    } else if (diff > month) {
      return 'Past 6 Months'
    } else if (diff > day7) {
      return 'Past Month'
    } else if (diff > day) {
      return 'Past Week'
    } else {
      return 'Today'
    }
  }

  return 'Never'
}

export const UserLoginList = () => {
  const apiListUserLogin = useApiListUserLogin()

  const roleOrgUser = useRoleOrgUser()
  const roleOrgNodeUser = useRoleOrgNodeUser()
  const roleOrgGroupUser = useRoleOrgGroupUser()

  const handleReady = async () => await apiListUserLogin.get()
  const handleDestroyed = () => apiListUserLogin.init()

  const tableRef = useRef()
  const handleExportCsv = () => tableRef.current.exportCsv()
  const handleExportExcel = () => tableRef.current.exportExcel()
  const handleTableSettingToggle = () => tableRef.current.toggleSideBar()

  const HeaderMenu = () =>
    <Menu>
      <MenuAdd text="add users" to="/org/user/list/add" show={roleOrgUser.accessCreate} />
      <MenuRecycle to="/org/user/list/recycle" show={roleOrgUser.accessDelete} />
      <MenuExportCsv onClick={handleExportCsv} show />
      <MenuExportExcel onClick={handleExportExcel} show />
      <MenuTableSettingToggle onClick={handleTableSettingToggle} show />
      <MenuActivityLog to="/org/user/list/activity-all" show />
    </Menu>

  const comparatorStatus = useCallback((value1, value2, node1, node2) => {
    if (!node1.data.invitePending && !node2.data.invitePending) {
      return -1
    }

    if (!node1.data.lastActive && !node2.data.lastActive) {
      return -1
    }

    let date1 = new Date(node1.data.lastActive)
    let date2 = new Date(node2.data.lastActive)

    if (date1 > date2) {
      return 1
    } else {
      return -1
    }
  }, [])

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconUsers} text="USERS">
        <CardFullHeightScrollY title="Users" HeaderMenu={HeaderMenu}>
          <TableAgClient ref={tableRef} apiList={apiListUserLogin} onReady={handleReady} onDestoryed={handleDestroyed}>
            <ColumnDateTime field="createdAt" hide />
            <ColumnText field="email" />
            <ColumnText field="firstName" />
            <ColumnText field="lastName" />
            <ColumnSelector field="lastActive" header="Status" selector={row => <BadgeStatus userLogin={row} />} comparator={comparatorStatus} />
            <ColumnSelector field="lastActive" header="Last Active" selector={row => lastActive(row)} />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuEdit to={`/org/user/list/edit/${row.userID}`} show={roleOrgUser.accessUpdate} />
                  <MenuItem Icon={IconContent} text="contents" to={`/org/user/list/edit/${row.userID}/content`} show={roleOrgNodeUser.accessRead} />
                  <MenuItem Icon={IconGroup} text="groups" to={`/org/user/list/edit/${row.userID}/group`} show={roleOrgGroupUser.accessRead} />
                  <MenuItem
                    text="analytics custom"
                    Icon={IconAnalytic}
                    to={`/org/user/list/edit/${row.userID}/analytic-custom`}
                    show={roleOrgGroupUser.accessRead}
                  />
                  <MenuItem
                    text="analytics general"
                    Icon={IconAnalytic}
                    to={`/org/user/list/edit/${row.userID}/analytic-general`}
                    show={roleOrgGroupUser.accessRead}
                  />
                  <MenuItem
                    text="assessments"
                    Icon={IconAssessment}
                    to={`/org/user/list/edit/${row.userID}/assessment-reports`}
                    show={roleOrgGroupUser.accessRead}
                  />

                  <MenuActivityLog to={`/org/user/list/edit/${row.userID}/activity-id/${row.userID}`} show />
                  <MenuDelete to={`/org/user/list/edit/${row.userID}/delete`} show={roleOrgUser.accessDelete} />
                </Menu>}
            />
          </TableAgClient>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
