import React from 'react'
import { LayoutRoot } from './root/layout-root'
import { LayoutOrg } from './org/layout-org'
import { LayoutAdmin } from './admin/layout-admin'

export const LayoutAuthorized = () => {
  return (
    <React.Fragment>
      <LayoutRoot />
      <LayoutAdmin />
      <LayoutOrg />
    </React.Fragment>
  )
}
