import React from 'react'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { useParamsInt } from '../../../../../../shared/hooks/location'
import { Menu, MenuAdd, MenuDelete } from '../../../../../../shared/menu/menu'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'
import { useApiListGroupUser } from '../../../../../../api-new/state/content'
import { IconGroup } from '../../../../../../shared/icon/icon'
import { PermissionGroupUserForUserModal } from '../../../../../../shared/permission-modal/permission-group-user-for-user-modal'
import { useModalActionID } from '../../../../../../shared/component/modal-dialog'
import { useRoleOrgGroupUser } from '../../../../../../role/hook/role-org'
import { TableAgClient } from '../../../../../../shared/table-builder/table-ag-client'
import { ColumnText, ColumnMenu } from '../../../../../../shared/table-builder/table-columns'

export const UserGroupList = () => {
  const { userID } = useParamsInt()

  const apiListGroupUser = useApiListGroupUser()

  const [target, show, hide] = useModalActionID()

  const handleReady = async () => await apiListGroupUser.get(`${userID}/user`)
  const handleDestroyed = () => apiListGroupUser.init()

  const handleAddShow = () => show()
  const handleClose = () => hide()

  const handleClosed = async () => apiListGroupUser.get(`${userID}/user`)

  const handleDelete = async item => {
    await apiListGroupUser.delete(`${item.groupID}/${item.userID}`)
    await apiListGroupUser.get(`${userID}/user`)
  }

  const roleOrgGroupUser = useRoleOrgGroupUser()

  const HeaderMenu = () =>
    <Menu>
      <MenuAdd text="add group" onClick={handleAddShow} show={roleOrgGroupUser.accessCreate} />
    </Menu>

  return (
    <React.Fragment>
      <PermissionGroupUserForUserModal target={target} userID={userID} onClose={handleClose} onClosed={handleClosed} />
      <BreadcrumbView Icon={IconGroup} text="groups">
        <CardFullHeightScrollY title="user groups" HeaderMenu={HeaderMenu}>
          <TableAgClient apiList={apiListGroupUser} hideFloatingFilter onReady={handleReady} onDestoryed={handleDestroyed}>
            <ColumnText field="groupName" />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuDelete onClick={() => handleDelete(row)} show={roleOrgGroupUser.accessDelete} />
                </Menu>}
            />
          </TableAgClient>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
