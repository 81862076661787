import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { useAuthContext, useRefresh } from '../../context/auth.context'

import { useLoad } from '../../shared/hooks/load'
import { useApiListNodeOrg } from '../../api-new/state/content'
import { useParamsInt } from '../../shared/hooks/location'
import { pathNodeTemplate } from '../../layout/authorized/org/node/common/shared/path-node-template'
import { pathNodeContent } from '../../layout/authorized/org/node/common/shared/path-node-content'
import { AlertError } from '../../shared/alert/alert-error'

export const RedirectContentEdit = () => {
  const history = useHistory()
  const { refreshToken } = useParams()
  const { nodeID } = useParamsInt()

  const [error, updateError] = useState()

  const authContext = useAuthContext()
  const { refreshRecreate } = useRefresh()

  const apiListNodeOrg = useApiListNodeOrg()

  const redirect = async () => {
    if (authContext.state.isAuth) {
      let nodes = await apiListNodeOrg.get()

      let node = nodes.find(i => i.id === nodeID)

      if (node && node.metadata) {
        let data = JSON.parse(node.metadata)

        if (data.DataType === 'CATEGORY' || data.DataType === 'COURSE') {
          let path = pathNodeContent(nodes, nodeID)
          history.replace(`${path}/edit`)
          return
        }

        if (data.DataType === 'COURSE_TEMPLATE') {
          let path = pathNodeTemplate(nodes, nodeID)
          history.replace(`${path}`)
          return
        }
      }
    } else if (refreshToken) {
      await refreshRecreate(refreshToken)
    } else {
      history.replace(`/auth/email?redirect=/redirect/content-edit/${nodeID}`)
    }
  }

  useLoad(async () => {
    try {
      await redirect()
    } catch (e) {
      updateError(e)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <React.Fragment>
      <AlertError error={error} />
      <div>COURSE EDIT REDIRECTING...</div>
    </React.Fragment>
  )
}
