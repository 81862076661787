import React from 'react'
import WidgetGroups from './user-login-edit/widget-group'
import WidgetNode from './user-login-edit/widget-node'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { DisplayRoleAdmin } from '../../../../../context/display-role-admin'
import { UserLoginDetails } from './user-login-edit/user-details'
import { UserRoles } from './user-login-edit/user-roles'
import { UserInvite } from './user-login-edit/user-invite'
import { HeatmapLayoutUser } from '../../../../../view/heatmap/layout-user'
import { useParamsInt } from '../../../../../shared/hooks/location'
import { TileBody, TileColumn11112 } from '../../../../../shared/tile/tile-column-21111'
import { CardFullHeightScrollY } from '../../../../../shared/card/card-full-height-scroll-y'
import { IconEdit } from '../../../../../shared/icon/icon'
import { useApiFormUserLogin } from '../../../../../api-new/state/content'
import { HeaderMenu } from './common/header-menu'

export const UserLoginEdit = () => {
  const { userID } = useParamsInt()

  const apiFormUserLogin = useApiFormUserLogin()

  const handlePath = async () => await apiFormUserLogin.get(`${userID}`)

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconEdit} text={apiFormUserLogin.form.email} onPath={handlePath}>
        <CardFullHeightScrollY title={apiFormUserLogin.form.email} HeaderMenu={HeaderMenu}>
          <TileBody>
            <TileColumn11112>
              <UserLoginDetails userLogin={apiFormUserLogin.form} />

              <DisplayRoleAdmin>
                <UserInvite userLogin={apiFormUserLogin.form} onUpdate={handlePath} />
              </DisplayRoleAdmin>
            </TileColumn11112>

            <TileColumn11112>
              <UserRoles userID={userID} />

              <roleOrgGroupUser accessRead>
                <WidgetGroups userID={userID} />
              </roleOrgGroupUser>

              <roleOrgNodeUser accessRead>
                <WidgetNode userID={userID} />
              </roleOrgNodeUser>
            </TileColumn11112>
          </TileBody>
        </CardFullHeightScrollY>
      </BreadcrumbView>
      <HeatmapLayoutUser />
    </React.Fragment>
  )
}
