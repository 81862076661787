import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useRefresh } from '../../context/auth.context'
import { useLoad } from '../../shared/hooks/load'
import { AlertError } from '../../shared/alert/alert-error'

export const RedirectImpersonate = () => {
  const history = useHistory()

  const [error, updateError] = useState()

  const { refreshToken } = useParams()

  const { refreshImpersonate } = useRefresh()

  const redirect = async () => {
    await refreshImpersonate(refreshToken)
    history.replace('/')
  }

  useLoad(
    async () => {
      try {
        await redirect()
      } catch (e) {
        updateError(e)
      }
    },
    [refreshToken]
  )

  return (
    <React.Fragment>
      <AlertError error={error} />
      <div>IMPERSONATE REDIRECTING...</div>
    </React.Fragment>
  )
}
