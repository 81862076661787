const ACCESS_TOKEN = 'accessToken'
const REFRESH_TOKEN = 'refreshToken'

export const getAccessToken = () => {
  let token = sessionStorage.getItem(ACCESS_TOKEN)

  if (!token) {
    token = sessionStorage.getItem(ACCESS_TOKEN)
  }

  return token
}

export const setAccessToken = (accessToken, isSession) => {
  if (isSession) sessionStorage.setItem(ACCESS_TOKEN, accessToken)
  else sessionStorage.setItem(ACCESS_TOKEN, accessToken)
}

export const getRefreshToken = () => {
  let token = sessionStorage.getItem(REFRESH_TOKEN)

  if (!token) {
    token = sessionStorage.getItem(REFRESH_TOKEN)
  }

  return token
}

export const setRefreshToken = (refreshToken, isSession) => {
  if (isSession) sessionStorage.setItem(REFRESH_TOKEN, refreshToken)
  else sessionStorage.setItem(REFRESH_TOKEN, refreshToken)
}

export const removeTokens = isSession => {
  sessionStorage.removeItem(ACCESS_TOKEN)
  sessionStorage.removeItem(REFRESH_TOKEN)
  // if (isSession) {
  //   sessionStorage.removeItem(ACCESS_TOKEN)
  //   sessionStorage.removeItem(REFRESH_TOKEN)
  // } else {
  //   sessionStorage.removeItem(ACCESS_TOKEN)
  //   sessionStorage.removeItem(REFRESH_TOKEN)
  // }
}
