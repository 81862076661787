import { useEffect, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'

const useGetValue = name => {
  const { getValues } = useFormContext()
  let values = getValues()
  return useMemo(() => values[name], [values, name])
}

export const useFormRequired = (name, required, disabled) => {
  const { setError, clearErrors } = useFormContext()

  let value = useGetValue(name)

  useEffect(
    () => {
      if (required) {
        if (value) clearErrors(name)
        else setError(name, { message: 'required' })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value, required, disabled]
  )
}

export const useFormShouldDirty = (name, defaultValue, shouldDirty) => {
  const { setValue } = useFormContext()

  useEffect(
    () => {
      if (defaultValue) {
        setTimeout(() => setValue(name, defaultValue, { shouldDirty }), 1)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [name, defaultValue, shouldDirty]
  )
}

export const useFormOnChange = (name, onChange) => {
  // const { watch } = useFormContext()

  let value = useGetValue(name)
  // let watchValue = watch(name)

  useEffect(
    () => {
      onChange && onChange(value)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value]
  )
}

export const useFormOnItemsChange = (name, items, onChange) => {
  // const { watch } = useFormContext()

  let value = useGetValue(name)
  // let watchValue = watch(name)

  useEffect(
    () => {
      onChange && onChange(value)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value, items]
  )
}

export const useFormSetValue = (name, value, shouldDirty) => {
  const { setValue } = useFormContext()
  setValue(name, value, { shouldDirty })
}

export const useFormDefaultValue = (name, defaultValue, shouldDirty) => {
  const { setValue } = useFormContext()

  useEffect(
    () => {
      setValue(name, defaultValue, { shouldDirty })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [defaultValue]
  )
}
