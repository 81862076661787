import React from 'react'
import { CardFullHeightScrollY } from '../../../../../shared/card/card-full-height-scroll-y'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { Menu, MenuPurge, MenuRestore } from '../../../../../shared/menu/menu'
import { useApiFormNode } from '../../../../../api-new/state/content'
import { useApiListNode } from '../../../../../api-new/state/content'
import { Route, useRouteMatch } from 'react-router-dom'
import { Purge } from '../../../../../shared/page/page-purge'
import { IconRecycle } from '../../../../../shared/icon/icon'
import { ColumnSelector, ColumnText, ColumnMenu } from '../../../../../shared/table-builder/table-columns'
import { TableAgClient } from '../../../../../shared/table-builder/table-ag-client'

export const NodeRecycle = () => {
  const { url } = useRouteMatch()

  const apiListNode = useApiListNode()

  const handleReady = () => apiListNode.get('deleted')
  const handleDestroyed = () => apiListNode.init()

  const handleRestore = async id => {
    await apiListNode.put(`${id}/restore`)
    await apiListNode.get('deleted')
  }

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconRecycle} text="recycle bin">
        <CardFullHeightScrollY title="recycle bin">
          <TableAgClient apiList={apiListNode} onReady={handleReady} onDestoryed={handleDestroyed}>
            <ColumnText field="name" />
            <ColumnSelector field="metaData" header="Type" selector={row => (row.metaData ? JSON.parse(row.metaData.toLowerCase()).datatype : '')} />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuRestore onClick={() => handleRestore(row.id)} show />
                  <MenuPurge to={`${url}/${row.id}/purge`} show />
                </Menu>}
            />
          </TableAgClient>
        </CardFullHeightScrollY>
      </BreadcrumbView>

      <Route path={`${url}/:purgeID/purge`}>
        <Purge useApiForm={useApiFormNode} titleParam="name" successPath={`${url}`} />
      </Route>
    </React.Fragment>
  )
}
