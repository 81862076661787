import React, { useEffect, useMemo, useState } from 'react'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { useParams } from 'react-router-dom'
import { Form, FormText, FormTextArea, FormSubmit, FormHiddenBool, FormHidden, FormDate } from '../../../../../shared/form/form'
import { FormSelectGroupID } from '../../../../../shared/form/form-control/form-select-group-id'
import { FormColor } from '../../../../../shared/form/form-control/form-color'
import { useApFormTradeshow } from '../../../../../api-new/state/content'
import { IconEdit } from '../../../../../shared/icon/icon'
import { CardFullHeightScrollY } from '../../../../../shared/card/card-full-height-scroll-y'
import { TradeshowLogo } from './tradeshow-edit/tradeshow-logo'

const FormFieldTradeShowAvailability = ({ initalValue }) => {
  const [optionValue, updateOptionValue] = useState()

  const [closed, updateClosed] = useState()
  const [activeUntilEnd, updateActiveUntilEnd] = useState()

  const [minEndOn, updateMinEndOn] = useState()

  const hideDates = useMemo(() => optionValue === 'always', [optionValue])

  useEffect(
    () => {
      if (initalValue) {
        if (initalValue.closed) {
          updateOptionValue('closed')
        } else if (initalValue.activeUntilEnd) {
          updateOptionValue('until')
        } else if (initalValue.startOn && initalValue.endOn) {
          updateOptionValue('during')
        } else {
          updateOptionValue('always')
        }
      }
    },
    [initalValue]
  )

  const items = [
    { text: 'Open Always', value: 'always' },
    { text: 'Open Between Start and End Dates', value: 'during' },
    { text: 'Open Until End Date', value: 'until' },
    { text: 'Closed', value: 'closed' }
  ]

  const handleChange = e => {
    updateOptionValue(e.target.value)

    switch (e.target.value) {
      case 'always':
        updateClosed(false)
        updateActiveUntilEnd(false)
        break
      case 'during':
        updateClosed(false)
        updateActiveUntilEnd(false)
        break
      case 'until':
        updateClosed(false)
        updateActiveUntilEnd(true)
        break
      case 'closed':
        updateClosed(true)
        updateActiveUntilEnd(false)
        break
      default:
        updateClosed(false)
        updateActiveUntilEnd(false)
    }
  }

  const handleStartChange = value => updateMinEndOn(value)

  return (
    <React.Fragment>
      <FormHiddenBool name="closed" value={closed} shouldDirty />
      <FormHiddenBool name="activeUntilEnd" value={activeUntilEnd} shouldDirty />

      <div className="form-group">
        <label>Availability</label>
        <select className="form-control" value={optionValue} onChange={handleChange}>
          {items &&
            items.map((item, i) =>
              <option key={i} value={item.value}>
                {item.text}
              </option>
            )}
        </select>
      </div>

      {hideDates
        ? <FormHidden name="startOn" value={null} />
        : <FormDate label="starts on" name="startOn" hidden={hideDates} required onChange={handleStartChange} />}

      {hideDates ? <FormHidden name="endOn" value={null} /> : <FormDate label="ends on" name="endOn" showTime minDate={minEndOn} hidden={hideDates} required />}
    </React.Fragment>
  )
}

export const TradeShowEdit = () => {
  const { tradeshowID } = useParams()

  const apFormTradeshow = useApFormTradeshow()

  const handlePath = () => apFormTradeshow.get(tradeshowID)

  const handleSubmit = async data => await apFormTradeshow.put(data.id, data)
  const handleRefresh = async () => apFormTradeshow.get(tradeshowID)

  const initalValue = useMemo(() => apFormTradeshow.form, [apFormTradeshow.form])

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconEdit} text={apFormTradeshow.form.name} onPath={handlePath}>
        <CardFullHeightScrollY title="edit trade show">
          <Form state={apFormTradeshow.form} submitting={apFormTradeshow.submitting} onSubmit={handleSubmit} onRefresh={handleRefresh}>
            <FormText label="name" name="name" />
            <FormTextArea label="description" name="description" required />
            <FormTextArea label="inactive message" name="inactiveMessage" required />
            <FormFieldTradeShowAvailability initalValue={initalValue} />
            <FormSelectGroupID label="group" name="groupID" required />
            <FormColor label="background color" name="colorBackground" />
            <FormColor label="text color" name="colorText" />
            <FormColor label="primary button background" name="colorButtonPrimaryBackground" />
            <FormColor label="primary button text" name="colorButtonPrimaryText" />
            <FormColor label="secondary button background" name="colorButtonSecondaryBackground" />
            <FormColor label="secondary button text" name="colorButtonSecondaryText" />
            <FormSubmit text="update tradeshow" />
          </Form>

          <TradeshowLogo identifier={apFormTradeshow.form.identifier} />
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
