import React from 'react'

import { DisplayAuth } from '../../context/display-auth'

import { RedirectDashboard } from '../redirect-dashboard'
import { Timeout } from '../../shared/component/timeout'
import { LayoutAuthorized } from '../../layout/authorized/layout-authorized'

export const RouteAuthorized = () => {
  return (
    <React.Fragment>
      <RedirectDashboard>
        <Timeout />
        <DisplayAuth>
          <LayoutAuthorized />
        </DisplayAuth>
      </RedirectDashboard>
    </React.Fragment>
  )
}
