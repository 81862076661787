import React, { useMemo, useRef } from 'react'
import { useParamsInt } from '../../../shared/hooks/location'
import { useApiFormUserLogin } from '../../../api-new/state/content'
import { useApiListReportCustomOrg } from '../../../api-new/state/analytics'
import { useLoad } from '../../../shared/hooks/load'
import { Menu, MenuExportCsv, MenuExportExcel } from '../../../shared/menu/menu'
import { useParseJSON } from '../course/analytic-course-report/hook'
import { BreadcrumbView } from '../../../context/breadcrumb.context'
import { IconTable } from '../../../shared/icon/icon'
import { CardFullHeightScrollY } from '../../../shared/card/card-full-height-scroll-y'
import { TableAgClient } from '../../../shared/table-builder/table-ag-client'
import { ColumnDateTime, ColumnSelector, ColumnText, ColumnYesNo } from '../../../shared/table-builder/table-columns'
// import { useParamsInt } from '../../../shared/hooks/location'
// import { useApiListReportCustomOrg } from '../../../api-new/state/analytics'
// import { IconTable } from '../../../shared/icon/icon'
// import { BreadcrumbView } from '../../../context/breadcrumb.context'
// import { useLoad } from '../../../shared/hooks/load'
// import { CardFullHeightScrollY } from '../../../shared/card/card-full-height-scroll-y'
// import { useApiFormUserLogin } from '../../../api-new/state/content'
// import { Menu, MenuExportCsv, MenuExportExcel } from '../../../shared/menu/menu'
// import { useParseJSON } from '../course/analytic-course-report/hook'
// import { TableAgClient } from '../../../shared/table-builder/table-ag-client'
// import { ColumnDateTime, ColumnSelector, ColumnText, ColumnYesNo } from '../../../shared/table-builder/table-columns'

export const AnalyticCustomUser = () => {
  const { userID } = useParamsInt()

  const apiFormUserLogin = useApiFormUserLogin()
  const apiListReportCustomOrg = useApiListReportCustomOrg()

  useLoad(() => apiFormUserLogin.get(userID), [])

  const handleReady = () => apiListReportCustomOrg.get(`${userID}/user`)
  const handleDestroyed = () => apiListReportCustomOrg.init()

  const title = useMemo(() => `Analytics - ${apiFormUserLogin.form.email}`, [apiFormUserLogin.form])

  const tableRef = useRef()
  const handleExportCsv = () => tableRef.current.exportCsv()
  const handleExportExcel = () => tableRef.current.exportExcel()

  const HeaderMenu = () =>
    <Menu>
      <MenuExportCsv onClick={handleExportCsv} show />
      <MenuExportExcel onClick={handleExportExcel} show />
    </Menu>

  let parseValue = useParseJSON()

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconTable} text="analytics">
        <CardFullHeightScrollY title={title} HeaderMenu={HeaderMenu}>
          <TableAgClient ref={tableRef} apiList={apiListReportCustomOrg} onReady={handleReady} onDestoryed={handleDestroyed}>
            <ColumnDateTime field="capturedAt" />
            <ColumnText field="sessionID" />
            <ColumnText field="nodeName" header="Course" />

            <ColumnText field="topicName" />
            <ColumnText field="analyticName" />

            <ColumnSelector field="value" selector={row => parseValue(row.value)} />
            <ColumnText field="type" />

            <ColumnText field="email" />
            <ColumnText field="firstName" />
            <ColumnText field="lastName" />

            <ColumnDateTime field="startOn" />
            <ColumnDateTime field="endOn" />

            <ColumnText field="os" />
            <ColumnText field="platform" />
            <ColumnText field="appVersion" />

            <ColumnText field="deviceModel" />
            <ColumnText field="deviceType" />
            <ColumnText field="vrDeviceModel" />
            <ColumnText field="vrDeviceName" />
            <ColumnText field="vrDevicePresent" />
            <ColumnText field="vrDeviceTrackingOriginMode" />
            <ColumnText field="vrDeviceTrackingSpaceType" />
            <ColumnText field="vrDeviceRefreshRate" />
            <ColumnText field="graphicsCard" />
            <ColumnText field="graphicsCardMemorySize" />
            <ColumnText field="graphicsCardVendor" />
            <ColumnText field="processor" />
            <ColumnText field="processorCount" />
            <ColumnText field="processorFrequency" />
            <ColumnText field="systemMemorySize" />
            <ColumnYesNo field="isMultiPlayer" />

            <ColumnText field="locationCountry" />
            <ColumnText field="locationRegion" />
            <ColumnText field="locationCity" />

            <ColumnText field="groups" />
          </TableAgClient>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
