import React, { useRef } from 'react'
import { useParams, useRouteMatch } from 'react-router'
import { BreadcrumbView } from '../../../../context/breadcrumb.context'
import { IconList } from '../../../../shared/icon/icon'
import { CardFullHeightScrollY } from '../../../../shared/card/card-full-height-scroll-y'
import { useApiListResultAssessmentCourse } from '../../../../api-new/state/assessment'
import { Menu, MenuExportCsv, MenuExportExcel, MenuItem, MenuRecycle } from '../../../../shared/menu/menu'
import { IconView, IconUser } from '../../../../shared/icon/icon'
import { TableAgClient } from '../../../../shared/table-builder/table-ag-client'
import { ColumnMenu, ColumnText } from '../../../../shared/table-builder/table-columns'

export function AssessmentCourseReports() {
  const { url } = useRouteMatch()
  const { nodeID } = useParams()

  const apiListTestResultAssessmentCourse = useApiListResultAssessmentCourse()

  const handleReady = async () => await apiListTestResultAssessmentCourse.get(`reports/${nodeID}/node`)
  const handleDestroyed = () => apiListTestResultAssessmentCourse.init()

  const tableRef = useRef()
  const handleExportCsv = () => tableRef.current.exportCsv()
  const handleExportExcel = () => tableRef.current.exportExcel()

  const HeaderMenu = () =>
    <Menu>
      <MenuRecycle to={`${url}/recycle`} show />
      <MenuExportCsv onClick={handleExportCsv} show />
      <MenuExportExcel onClick={handleExportExcel} show />
    </Menu>

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconList} text="Assessment Reports">
        <CardFullHeightScrollY title="Assessment Reports" HeaderMenu={HeaderMenu}>
          <TableAgClient ref={tableRef} apiList={apiListTestResultAssessmentCourse} onReady={handleReady} onDestoryed={handleDestroyed}>
            <ColumnText field="title" header="Assessment" sortable filter />
            <ColumnText field="type" sortable filter />
            <ColumnText field="attempts" header="# of Attempts" sortable filter />
            <ColumnText field="users" header="# of Users" sortable filter />
            <ColumnText field="passed" header="# of Passed" sortable filter />
            <ColumnText field="avgScore" header="Average Score" sortable filter />
            <ColumnText field="avgDuration" header="Average Duration (Min:Sec)" sortable filter />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuItem text="view" Icon={IconView} to={`${url}/view/${row.assessmentID}`} show pin />
                  <MenuItem text="users" Icon={IconUser} to={`${url}/users/${row.assessmentID}`} show pin />
                </Menu>}
            />
          </TableAgClient>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
