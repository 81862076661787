import React from 'react'
import { useLoad } from '../../shared/hooks/load'
import { useBuildRouteRedirect } from '../../layout/unauthorized/auth/hooks/redirect'

export const AuthRedirect = () => {
  const buildRouteRedirect = useBuildRouteRedirect()

  useLoad(() => {
    let value = buildRouteRedirect('/auth/email')
    window.location.replace(value)
  }, [])

  return <React.Fragment />
}
