import React, { useRef } from 'react'
import { Menu, MenuDelete, MenuDetails, MenuExportCsv, MenuExportExcel, MenuRecycle, MenuTableSettingToggle } from '../../../../../../shared/menu/menu'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'
import { IconList } from '../../../../../../shared/icon/icon'
import { useApiListChunkErrorLog } from '../../../../../../api-new/state/content'
import { ColumnDateTime, ColumnMenu, ColumnText } from '../../../../../../shared/table-builder/table-columns'
import { TableAgInfinite } from '../../../../../../shared/table-builder/table-ag-infinite'

export function LogErrorList() {
  const apiListChunkErrorLog = useApiListChunkErrorLog()

  const handleGetRows = async (start, end, search, sortCol, sortDir) => {
    return await apiListChunkErrorLog.get(`range/${start}/${end}?search=${search}&sort-col=${sortCol}&sort-dir=${sortDir}`)
  }

  const handleDestroyed = () => apiListChunkErrorLog.init()

  const handleDelete = async item => {
    try {
      await apiListChunkErrorLog.delete(item.id)
      await apiListChunkErrorLog.remove(item)
    } catch (error) {}
  }

  let tableRef = useRef()
  const handleExportCsv = () => tableRef.current.exportCsv()
  const handleExportExcel = () => tableRef.current.exportExcel()
  const handleTableSettingToggle = () => tableRef.current.toggleSideBar()

  const HeaderMenu = () =>
    <Menu>
      <MenuExportCsv onClick={handleExportCsv} show />
      <MenuExportExcel onClick={handleExportExcel} show />
      <MenuTableSettingToggle onClick={handleTableSettingToggle} show />
      <MenuRecycle to="/admin/dashboard/log-error/list/recycle" show />
    </Menu>

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconList} text="errors">
        <CardFullHeightScrollY title="errors" HeaderMenu={HeaderMenu}>
          <TableAgInfinite ref={tableRef} onGetRows={handleGetRows} onDestoryed={handleDestroyed}>
            {/* <ColumnIndex /> */}
            <ColumnDateTime field="createdAt" />
            <ColumnText field="method" />
            <ColumnText field="statusCode" />
            <ColumnText field="message" />
            <ColumnText field="email" />
            <ColumnText field="organization" />
            <ColumnText field="sessionID" hide />

            <ColumnText field="app" />
            <ColumnText field="appVersion" />
            <ColumnText field="deviceName" />
            <ColumnText field="deviceType" />
            <ColumnText field="deviceOS" />
            <ColumnText field="devicePlatform" />

            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuDetails text="details" to={`/admin/dashboard/log-error/list/edit/${row.id}`} show />
                  <MenuDelete onClick={() => handleDelete(row)} show />
                </Menu>}
            />
          </TableAgInfinite>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
