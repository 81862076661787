import React, { useMemo, useState } from 'react'
import { Draggable, DragHandle, Droppable } from '../../legacy/drag-drop/drag-drop'
import { useFormDisable } from '../form'
import { FormatLabel } from '../format-label'
import { IconGrip } from '../../icon/icon'
import { IconClickAdd, IconClickDelete } from '../../icon/icon-click'
import { useFormContext } from 'react-hook-form'

const ListItems = ({ items, disabled, onChange }) => {
  const [newItem, updateNewItem] = useState('')

  let itemArray = useMemo(() => items || [], [items])

  const handleDropList = (dragIndex, dropIndex) => {
    let hold = itemArray[dragIndex]
    itemArray.splice(dragIndex, 1)
    itemArray.splice(dropIndex, 0, hold)
    onChange(itemArray)
  }

  const handleChangeList = (value, i) => {
    itemArray[i] = value
    onChange(itemArray)
  }

  const handleDeleteList = i => {
    itemArray.splice(i, 1)
    onChange(itemArray)
  }

  const handleChangeNewItem = e => updateNewItem(e.target.value)

  const handleKeyUpNewItem = e => {
    e.preventDefault()
    if (e.code === 'Enter' && newItem) {
      itemArray.push(newItem)
      updateNewItem('')
      onChange(itemArray)
    }
  }

  const handleClickNewItem = () => {
    if (newItem) {
      itemArray.push(newItem)
      updateNewItem('')
      onChange(itemArray)
    }
  }

  const handleKeyDown = e => {
    if (e.code === 'Enter') {
      e.preventDefault()
    }
  }

  return (
    <React.Fragment>
      {itemArray.map((item, i) =>
        <Draggable key={i} draggable dragID={i}>
          <div className="input-group">
            <div className="input-group-text">
              <DragHandle>
                <Droppable onDrop={dragIndex => handleDropList(dragIndex, i)}>
                  <IconGrip />
                </Droppable>
              </DragHandle>
            </div>
            <div className="input-group-text" style={{ width: '40px' }}>
              {i + 1}
            </div>
            <input className="form-control" value={item} disabled={disabled} onChange={e => handleChangeList(e.target.value, i)} />
            <div className="input-group-text" style={{ cursor: 'pointer' }} onClick={() => handleDeleteList(i)}>
              <IconClickDelete />
            </div>
          </div>
        </Draggable>
      )}

      <div className="input-group">
        <input
          className="form-control"
          placeholder="New Objective"
          value={newItem}
          disabled={disabled}
          onChange={handleChangeNewItem}
          onKeyUp={handleKeyUpNewItem}
          onKeyDown={handleKeyDown}
        />

        <div className="input-group-text" style={{ cursor: 'pointer' }} onClick={handleClickNewItem}>
          <IconClickAdd />
        </div>
      </div>
    </React.Fragment>
  )
}

export const FormCourseMetadata = ({ name, onChange }) => {
  const { watch, setValue } = useFormContext()

  const watchValue = watch(name)

  const metadata = useMemo(() => (watchValue ? JSON.parse(watchValue) : {}), [watchValue])

  let formDisabled = useFormDisable()

  // const handlePublic = e => {
  //   metadata.IsPublic = e.target.value === 'true'
  //   let jsonValue = JSON.stringify(metadata)
  //   setValue(name, jsonValue, { shouldDirty: true })
  // }

  const handleOverview = e => {
    metadata.OverviewString = e.target.value
    let jsonValue = JSON.stringify(metadata)
    setValue(name, jsonValue, { shouldDirty: true })
  }

  const handleChangeList = value => {
    metadata.ObjectivesStringList = value
    let jsonValue = JSON.stringify(metadata)
    setValue(name, jsonValue, { shouldDirty: true })
  }

  return (
    <React.Fragment>
      {/* <FormatLabel label="is public" name={name} hasValue={!!watchValue}>
        <select className="form-control" value={metadata.IsPublic} onChange={handlePublic} disabled={formDisabled}>
          <option value={false}>No</option>
          <option value={true}>Yes</option>
        </select>
      </FormatLabel> */}

      <FormatLabel label="overview">
        <div>
          <textarea className="form-control" value={metadata.OverviewString} onChange={handleOverview} disabled={formDisabled} />
        </div>
      </FormatLabel>

      <FormatLabel label="objectives">
        <ListItems items={metadata.ObjectivesStringList} onChange={handleChangeList} disabled={formDisabled} />
      </FormatLabel>
    </React.Fragment>
  )
}
