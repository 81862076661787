import React from 'react'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { useParamsInt } from '../../../../../../shared/hooks/location'
import { Menu, MenuAdd, MenuRemove } from '../../../../../../shared/menu/menu'
import { useApiListNodeGroup } from '../../../../../../api-new/state/content'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'
import { IconUsers } from '../../../../../../shared/icon/icon'
import { useModalActionID } from '../../../../../../shared/component/modal-dialog'
import { PermissionNodeGroupForGroupModal } from '../../../../../../shared/permission-modal/permission-node-group-for-group-modal'
import { useRoleOrgGroupUser } from '../../../../../../role/hook/role-org'
import { TableAgClient } from '../../../../../../shared/table-builder/table-ag-client'
import { ColumnText, ColumnMenu } from '../../../../../../shared/table-builder/table-columns'

export const GroupListContent = () => {
  const { groupID } = useParamsInt()

  const apiListNodeGroup = useApiListNodeGroup()

  const [target, show, hide] = useModalActionID()

  const handleReady = async () => await apiListNodeGroup.get(`${groupID}/group`)
  const handleDestroyed = () => apiListNodeGroup.init()

  const handleAddShow = () => show()
  const handleClose = () => hide()

  const handleClosed = async () => apiListNodeGroup.get(`${groupID}/group`)

  const handleDelete = async item => {
    await apiListNodeGroup.delete(`${item.nodeID}/${item.groupID}`)
    await apiListNodeGroup.get(`${groupID}/group`)
  }

  const roleOrgGroupUser = useRoleOrgGroupUser()

  const HeaderMenu = () =>
    <Menu>
      <MenuAdd text="add" onClick={handleAddShow} show={roleOrgGroupUser.accessCreate} />
    </Menu>

  return (
    <React.Fragment>
      <PermissionNodeGroupForGroupModal target={target} groupID={groupID} onClose={handleClose} onClosed={handleClosed} />
      <BreadcrumbView Icon={IconUsers} text="content">
        <CardFullHeightScrollY title="content" HeaderMenu={HeaderMenu}>
          <TableAgClient apiList={apiListNodeGroup} onReady={handleReady} onDestoryed={handleDestroyed}>
            <ColumnText field="nodeName" />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuRemove text="remove" onClick={() => handleDelete(row)} show />
                </Menu>}
            />
          </TableAgClient>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
