import React, { useEffect } from 'react'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { useApiListAuthSession } from '../../../../../api-new/state/auth'
import { TableAgClient } from '../../../../../shared/table-builder/table-ag-client'
import { ColumnDateTime, ColumnMenu, ColumnText, ColumnYesNo } from '../../../../../shared/table-builder/table-columns'
import { Menu, MenuDelete } from '../../../../../shared/menu/menu'
import { useAuthContext } from '../../../../../context/auth.context'

export const LoginSession = () => {
  const authContext = useAuthContext()

  const apiListAuthSession = useApiListAuthSession()

  const handleReady = () => {
    if (authContext.state.loginID) {
      apiListAuthSession.get(`${authContext.state.loginID}/login`)
    }
  }

  const handleDestroyed = () => apiListAuthSession.init()

  useEffect(
    () => {
      if (authContext.state.loginID) {
        apiListAuthSession.get(`${authContext.state.loginID}/login`)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [authContext]
  )

  const handleDelete = async session => {
    await apiListAuthSession.delete(`${session.id}/self`)
    await apiListAuthSession.get(`${authContext.state.loginID}/login`)
  }

  return (
    <React.Fragment>
      <BreadcrumbView text="login sessions">
        <TableAgClient apiList={apiListAuthSession} onReady={handleReady} onDestoryed={handleDestroyed}>
          <ColumnYesNo field="isCurrentSession" header="Current Session" />
          <ColumnDateTime field="createdAt" header="Created" />
          <ColumnDateTime field="expiresOn" header="Expires" />
          <ColumnText field="ipAddress" header="IP" />
          <ColumnText field="ipLocation" header="Location" />
          <ColumnText field="app" />
          <ColumnText field="appVersion" />
          <ColumnText field="deviceName" />
          <ColumnText field="deviceType" />
          <ColumnText field="deviceOS" />
          <ColumnText field="devicePlatform" />
          <ColumnMenu
            menu={row =>
              <Menu small>
                <MenuDelete onClick={() => handleDelete(row)} show disabled={row.isCurrentSession} />
              </Menu>}
          />
        </TableAgClient>
      </BreadcrumbView>
    </React.Fragment>
  )
}
