import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import { useApiListAnalyticParentType } from '../../../../api-new/state/analytics'
import { Menu, MenuAdd, MenuDelete, MenuEdit, MenuRecycle } from '../../../../shared/menu/menu'
import { BreadcrumbView } from '../../../../context/breadcrumb.context'
import { IconList } from '../../../../shared/icon/icon'
import { CardFullHeightScrollY } from '../../../../shared/card/card-full-height-scroll-y'
import { TableAgClient } from '../../../../shared/table-builder/table-ag-client'
import { ColumnMenu, ColumnText } from '../../../../shared/table-builder/table-columns'

export const AnalyticParentTypeList = () => {
  const { url } = useRouteMatch()

  const apiListAnalyticParentType = useApiListAnalyticParentType()

  const handleReady = () => apiListAnalyticParentType.get()
  const handleDestroyed = () => apiListAnalyticParentType.init()

  const handleDelete = async data => {
    await apiListAnalyticParentType.delete(data.id)
    await apiListAnalyticParentType.get()
  }

  const HeaderMenu = () =>
    <Menu>
      <MenuAdd to={`${url}/add`} show />
      <MenuRecycle to={`${url}/recycle`} show />
    </Menu>

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconList} text="Parent Types">
        <CardFullHeightScrollY title="Analytic Parent Types" HeaderMenu={HeaderMenu}>
          <TableAgClient apiList={apiListAnalyticParentType} onReady={handleReady} onDestoryed={handleDestroyed}>
            <ColumnText field="name" />
            <ColumnText field="description" />
            <ColumnText field="analyticParentCount" header="Analytic Parent" />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuEdit to={`${url}/${row.id}/edit`} show />
                  <MenuDelete onClick={() => handleDelete(row)} show />
                </Menu>}
            />
          </TableAgClient>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
