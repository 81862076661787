import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useFormDisable } from './form'
import { FormatLabel } from './format-label'
import { useFormRequired, useFormSetValue, useFormShouldDirty } from './hooks/input-hooks'

export const FormNumber = ({ label, value, placeholder, subtext, name, required, readOnly, defaultValue, shouldDirty, autoFocus, min, max, step }) => {
  const { register, watch } = useFormContext()

  let formDisabled = useFormDisable()

  let watchValue = watch(name)

  useFormRequired(name, required)

  useFormSetValue(name, value, shouldDirty)

  useFormShouldDirty(name, defaultValue, shouldDirty)

  return (
    <React.Fragment>
      <FormatLabel name={name} label={label} subtext={subtext} hasValue={!!watchValue} required={required}>
        <input
          className="form-control"
          placeholder={placeholder || label}
          autoFocus={autoFocus}
          type="number"
          min={min}
          max={max}
          step={step}
          {...register(name, { required, valueAsNumber: true })}
          disabled={formDisabled}
          readOnly={readOnly}
        />
      </FormatLabel>
    </React.Fragment>
  )
}
