import React, { useEffect, useMemo, useState } from 'react'
import { useLoad } from '../shared/hooks/load'
import { useQueryParam } from '../shared/hooks/location'
import { Card } from '../shared/card/card'
import { useApiFormAuthProvider } from '../api-new/state/auth'
import { ButtonPrimary } from '../shared/component/button'

export const OpenComplete = () => {
  const token = useQueryParam('token')

  const [error, updateError] = useState()

  const [hasRedirect, updateHasRedirect] = useState(false)

  const apiFormAuthProvider = useApiFormAuthProvider()

  useLoad(async () => {
    await apiFormAuthProvider.get(`${token}/details`)
  }, [])

  let url = useMemo(
    () => {
      if (apiFormAuthProvider.form.redirectSigninURL) {
        let url = new URL(apiFormAuthProvider.form.redirectSigninURL)
        url.searchParams.set('token', token)

        updateHasRedirect(true)

        return url
      }

      return null
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [apiFormAuthProvider]
  )

  useEffect(
    () => {
      if (url) {
        try {
          window.location = url.href
        } catch (error) {
          updateError(error)
        }
      }
    },
    [url]
  )

  const handleOpen = () => {
    window.location = url.href
  }

  return (
    <React.Fragment>
      {hasRedirect
        ? <Card>
            <div>
              <p>Login successful, you are now being returned to the app…</p>
              <p>If for some reason you are not automatically redirected close this window and return to the app to finish signing in.</p>

              <div>
                {error &&
                  <div className="border-top pt-3">
                    <p>An error occurred opening the EducationXR App from your browser.</p>
                    <ButtonPrimary onClick={handleOpen}>Retry</ButtonPrimary>
                  </div>}
              </div>
            </div>
          </Card>
        : <Card>
            <div className="pt-3">
              <p>Login successful, please close this window and return to the app to complete the sign in.</p>
            </div>
          </Card>}
    </React.Fragment>
  )
}
