import React from 'react'
import {  useAuthContext } from '../../../../context/auth.context'
import { BreadcrumbView } from '../../../../context/breadcrumb.context'
import { IconList } from '../../../../shared/icon/icon'
import { CardFullHeightScrollY } from '../../../../shared/card/card-full-height-scroll-y'
import { TableAgClient } from '../../../../shared/table-builder/table-ag-client'
import { ColumnText } from '../../../../shared/table-builder/table-columns'
import { useApiListAuthOrganization } from '../../../../api-new/state/auth'
import { useLoginRedirect } from '../hooks/redirect'

export function LoginAccount() {

  const authContext = useAuthContext()

  const apiListAuthOrganization = useApiListAuthOrganization()

  const loginRedirect = useLoginRedirect()

  const handleReady = async () => apiListAuthOrganization.get(authContext.state.refreshToken)

  const handleClicked = async data => {
    try {
      const authToken = await apiListAuthOrganization.put(authContext.state.refreshToken, data)
      authContext.setAuthToken(authToken)
     loginRedirect() 
    } catch {}
  }

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconList} text="switch organization">
      <CardFullHeightScrollY title="select organization">
          <TableAgClient apiList={apiListAuthOrganization} hideFloatingFilter onRowClicked={handleClicked} onReady={handleReady}>
            <ColumnText field="name" headerName="Organization"  />
          </TableAgClient>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
