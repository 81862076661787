import React from 'react'
import { Route, useHistory, useRouteMatch } from '../../../../node_modules/react-router-dom/cjs/react-router-dom.min'
import { AnalyticParentTypeList } from './analytic-parent-type/analytic-parent-type-list'
import { AnalyticParentTypeAdd } from './analytic-parent-type/analytic-parent-type-add'
import { AnalyticParentTypeEdit } from './analytic-parent-type/analytic-parent-type-edit'
import { AnalyticParentTypeRecycle } from './analytic-parent-type/analytic-parent-type-recycle'
import { AnalyticParentTypePurge } from './analytic-parent-type/analytic-parent-type-purge'
import { BreadcrumbView } from '../../../context/breadcrumb.context'
import { IconAnalytic } from '../../../shared/icon/icon'
import { AnalyticParentList } from './analytic-parent/analytic-parent-list'
import { AnalyticParentAdd } from './analytic-parent/analytic-parent-add'
import { AnalyticParentEdit } from './analytic-parent/analytic-parent-edit'
import { AnalyticParentRecycle } from './analytic-parent/analytic-parent-recycle'
import { AnalyticParentPurge } from './analytic-parent/analytic-parent-purge'
import { AnalyticTypeList } from './analytic-type/analytic-type-list'
import { AnalyticTypeAdd } from './analytic-type/analytic-type-add'
import { AnalyticTypeEdit } from './analytic-type/analytic-type-edit'
import { AnalyticTypeRecycle } from './analytic-type/analytic-type-recycle'
import { AnalyticTypePurge } from './analytic-type/analytic-type-purge'
import { AnalyticCard } from '../common/analytic-card'

export const AnalyticConfigure = () => {
  const { url } = useRouteMatch()

  const history = useHistory()

  const handleParentType = () => history.push(`${url}/parent-type`)
  const handleAnalyticParent = () => history.push(`${url}/parent`)
  const handleType = () => history.push(`${url}/type`)

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconAnalytic} text="ANALYTIC CONFIGURE">
        <div className="container p-2">
          <div className="row g-2">
            <div className="col-12 col-lg-4">
              <AnalyticCard name="Analytic Parent Type" description="Edit analytic parent type" onClick={handleParentType} />
            </div>
            <div className="col-12 col-lg-4">
              <AnalyticCard name="Analytic Parent" description="Edit analytic parent" onClick={handleAnalyticParent} />
            </div>
            <div className="col-12 col-lg-4">
              <AnalyticCard name="Analytic Type" description="Edit analytic type" onClick={handleType} />
            </div>
          </div>
        </div>
      </BreadcrumbView>

      <Route path="/root/analytic-configure/parent-type" component={AnalyticParentTypeList} />
      <Route path="/root/analytic-configure/parent-type/add" component={AnalyticParentTypeAdd} />
      <Route path="/root/analytic-configure/parent-type/:id/edit" component={AnalyticParentTypeEdit} />
      <Route path="/root/analytic-configure/parent-type/recycle" component={AnalyticParentTypeRecycle} />
      <Route path="/root/analytic-configure/parent-type/recycle/:id/purge" component={AnalyticParentTypePurge} />

      <Route path="/root/analytic-configure/parent" component={AnalyticParentList} />
      <Route path="/root/analytic-configure/parent/add" component={AnalyticParentAdd} />
      <Route path="/root/analytic-configure/parent/:id/edit" component={AnalyticParentEdit} />
      <Route path="/root/analytic-configure/parent/recycle" component={AnalyticParentRecycle} />
      <Route path="/root/analytic-configure/parent/recycle/:id/purge" component={AnalyticParentPurge} />

      <Route path="/root/analytic-configure/type" component={AnalyticTypeList} />
      <Route path="/root/analytic-configure/type/add" component={AnalyticTypeAdd} />
      <Route path="/root/analytic-configure/type/:id/edit" component={AnalyticTypeEdit} />
      <Route path="/root/analytic-configure/type/recycle" component={AnalyticTypeRecycle} />
      <Route path="/root/analytic-configure/type/recycle/:id/purge" component={AnalyticTypePurge} />
    </React.Fragment>
  )
}
