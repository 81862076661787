import React from 'react'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { CardFullHeightScrollY } from '../../../../../shared/card/card-full-height-scroll-y'
import { IconAuthLogin } from '../../../../../shared/icon/icon'
import { useApiListAuthHistory } from '../../../../../api-new/state/auth'
import { useParamsInt } from '../../../../../shared/hooks/location'
import { TableAgClient } from '../../../../../shared/table-builder/table-ag-client'
import { ColumnDateTime, ColumnText } from '../../../../../shared/table-builder/table-columns'
import { HeaderMenu } from './common/header-menu'

export const UserLoginAuthHistory = () => {
  const { userID, authClaimID } = useParamsInt()

  const apiListAuthHistory = useApiListAuthHistory()

  const handleReady = async () => {
    if (authClaimID) await apiListAuthHistory.get(`${userID}/user/${authClaimID}/claims`)
    else await apiListAuthHistory.get(`${userID}/user`)
  }

  const handleDestroyed = () => apiListAuthHistory.init()

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconAuthLogin} text="Auth History">
        <CardFullHeightScrollY title="Auth History" HeaderMenu={HeaderMenu}>
          <TableAgClient apiList={apiListAuthHistory} onReady={handleReady} onDestoryed={handleDestroyed}>
            <ColumnDateTime field="createdAt" header="Created" />
            <ColumnText field="authType" />
            <ColumnText field="ipAddress" header="IP" />
            <ColumnText field="ipLocation" header="Location" />
            <ColumnText field="app" />
            <ColumnText field="appVersion" />
            <ColumnText field="deviceName" />
            <ColumnText field="deviceType" />
            <ColumnText field="deviceOS" />
            <ColumnText field="devicePlatform" />
          </TableAgClient>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
