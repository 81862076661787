import React, { useEffect, useMemo } from 'react'
import { useAuthContext, useLogout } from '../../context/auth.context'
import { useLoginProviderList } from '../../api/state'
import { OffcanvasClose } from '../../shared/component/offcanvas'
import { Link } from '../../../node_modules/react-router-dom/cjs/react-router-dom.min'
import { THEME_TYPE_DARK, THEME_TYPE_LIGHT, THEME_TYPE_SYSTEM, usePageContext } from '../../context/page.context'
import {
  IconUser,
  IconOrganization,
  IconThemeLight,
  IconThemeDark,
  IconPassword,
  IconLogout,
  IconPurge,
  IconLink,
  IconThemeSystem,
  IconAuthLogin
} from '../../shared/icon/icon'

const SidebarItemNavLink = ({ Icon, title, to, target }) => {
  return (
    <OffcanvasClose target={target}>
      <li className="nav-item py-1 edxr-sidebar-right-item edxr-pointer">
        <Link className="nav-link text-decoration-none text-uppercase fw-light" to={to || ''}>
          <Icon size="lg" />
          <span className="mx-2">
            {title}
          </span>
        </Link>
      </li>
    </OffcanvasClose>
  )
}

const SidebarItemNavClick = ({ Icon, title, target, onClick }) => {
  return (
    <OffcanvasClose target={target}>
      <li className="nav-item py-1 edxr-sidebar-right-item edxr-pointer">
        <span className="nav-link text-decoration-none text-uppercase fw-light" onClick={onClick}>
          <Icon size="lg" />
          <span className="mx-2">
            {title}
          </span>
        </span>
      </li>
    </OffcanvasClose>
  )
}

export const SidebarRight = ({ target }) => {
  const authContext = useAuthContext()
  const pageContext = usePageContext()

  const handleSystem = () => pageContext.theme.actions.setThemeSystem()
  const handleLight = () => pageContext.theme.actions.setThemeLight()
  const handleDark = () => pageContext.theme.actions.setThemeDark()

  const [loginProviderListActions, loginProviderListState] = useLoginProviderList()

  useEffect(
    () => {
      if (authContext.state.jwtClaims.loginID) {
        loginProviderListActions.get(authContext.state.jwtClaims.loginID)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [authContext.state.jwtClaims]
  )

  const showProviders = useMemo(() => loginProviderListState.items.length > 0, [loginProviderListState.items])

  const logout = useLogout()

  const handleLogout = async () => logout()

  return (
    <div className="d-flex flex-column h-100">
      <div className="flex-fill">
        <ul className="nav nav-pills flex-column mt-3 justify-content-between">
          {authContext.state.jwtClaims.multipleAccounts &&
            <SidebarItemNavLink Icon={IconOrganization} title="Change Organization" to="/auth/account" target={target} />}

          {showProviders && <SidebarItemNavLink title="Provider" Icon={IconLink} to="/org/login-provider" target={target} />}
          <SidebarItemNavLink title="Update Password" Icon={IconPassword} to="/org/password-update" target={target} />
          <SidebarItemNavLink title="Update Settings" Icon={IconUser} to="/org/login-setting" target={target} />
          <SidebarItemNavLink title="Login Sessions" Icon={IconAuthLogin} to="/org/login-session" target={target} />
          <SidebarItemNavLink title="delete account" Icon={IconPurge} to="/org/delete-account" target={target} />

          <SidebarItemNavClick title="Logout" Icon={IconLogout} target={target} onClick={handleLogout} />
        </ul>
      </div>

      <div className="border-top">
        <div className="m-2 mb-1 fw-bold">Appearance</div>
        <div className="d-flex ">
          {pageContext.theme.hasSystemTheme &&
            <Button Icon={IconThemeSystem} text="System" selected={pageContext.theme.type === THEME_TYPE_SYSTEM} onClick={handleSystem} />}
          <Button Icon={IconThemeLight} text="Light" selected={pageContext.theme.type === THEME_TYPE_LIGHT} onClick={handleLight} />
          <Button Icon={IconThemeDark} text="Dark" selected={pageContext.theme.type === THEME_TYPE_DARK} onClick={handleDark} />
        </div>
      </div>
    </div>
  )
}

const Button = ({ Icon, text, selected, onClick }) => {
  return (
    <div className={`btn btn-sm ${selected ? 'btn-primary' : 'btn-secondary'} flex-fill m-1`} onClick={onClick}>
      <div>
        <Icon />
      </div>
      <div>
        {text}
      </div>
    </div>
  )
}
