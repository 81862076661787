import { useLocation } from 'react-router-dom'
import { useParamsInt } from '../../../../../../shared/hooks/location'
import { Menu, MenuActivityLog, MenuDelete, MenuItem } from '../../../../../../shared/menu/menu'
import { IconAnalytic, IconAssessment, IconAuthLogin, IconContent, IconGroup } from '../../../../../../shared/icon/icon'
import { useRoleOrgGroupUser, useRoleOrgNodeUser, useRoleOrgUser } from '../../../../../../role/hook/role-org'

export const HeaderMenu = () => {
  const { userID } = useParamsInt()
  const { pathname } = useLocation()

  const roleOrgUser = useRoleOrgUser()
  const roleOrgNodeUser = useRoleOrgNodeUser()
  const roleOrgGroupUser = useRoleOrgGroupUser()

  return (
    <Menu>
      <MenuItem Icon={IconContent} text="contents" to={`/org/user/list/edit/${userID}/content`} show={roleOrgNodeUser.accessRead} />
      <MenuItem Icon={IconGroup} text="groups" to={`/org/user/list/edit/${userID}/group`} show={roleOrgGroupUser.accessRead} />
      <MenuItem text="analytics custom" Icon={IconAnalytic} to={`${pathname}/analytic-custom`} show={roleOrgGroupUser.accessRead} />
      <MenuItem text="analytics general" Icon={IconAnalytic} to={`${pathname}/analytic-general`} show={roleOrgGroupUser.accessRead} />
      <MenuItem text="assessments" Icon={IconAssessment} to={`${pathname}/assessment-reports`} show={roleOrgGroupUser.accessRead} />
      <MenuItem Icon={IconAuthLogin} text="auth sessions" to={`/org/user/list/edit/${userID}/auth-session`} show={roleOrgGroupUser.accessRead} />
      <MenuItem Icon={IconAuthLogin} text="auth history" to={`/org/user/list/edit/${userID}/auth-history`} show={roleOrgGroupUser.accessRead} />
      <MenuActivityLog to={`/org/user/list/edit/${userID}/activity-id/${userID}`} show />
      <MenuDelete to={`/org/user/list/edit/${userID}/delete`} show={roleOrgUser.accessDelete} />
    </Menu>
  )
}
