import React from 'react'
import { Route } from '../../../../../node_modules/react-router-dom/cjs/react-router-dom.min'
import { LogErrorList } from './content/log-error/log-error-list'
import { LogErrorEdit } from './content/log-error/log-error-edit'
import { LogErrorRecycle } from './content/log-error/log-error-recycle'
import { AdminDashboard } from './content/admin-dashboard'
import { LogEventList } from './content/log-event/log-event-list'
import { LogEventDetails } from './content/log-event/log-event-details'
import { LogEventRecycle } from './content/log-event/log-event-recycle'

export const LayoutDashboard = () => {
  return (
    <React.Fragment>
      <Route path="/admin/dashboard" component={AdminDashboard} />
      <Route path="/admin/dashboard/log-event/list" component={LogEventList} />
      <Route path="/admin/dashboard/log-event/list/recycle" component={LogEventRecycle} />
      <Route path="/admin/dashboard/log-event/list/edit/:logEventID" component={LogEventDetails} />
      <Route path="/admin/dashboard/log-error/list" component={LogErrorList} />
      <Route path="/admin/dashboard/log-error/list/recycle" component={LogErrorRecycle} />
      <Route path="/admin/dashboard/log-error/list/edit/:logErrorID" component={LogErrorEdit} />
    </React.Fragment>
  )
}
