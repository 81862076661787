import { getAccessToken } from '../../../../storage/auth'
import { useFetchPost } from '../../../../shared/hooks/fetch'

export const useDocAuthRedirect = () => {
  const fetchPost = useFetchPost()

  return async redirectDoc => {
    let url = process.env.REACT_APP_EDXR_DOCS
    let accessToken = getAccessToken()
    if (accessToken) {
      let body = JSON.stringify({ accessToken: accessToken })
      let result = await fetchPost(`${url}/auth`, body)

      if (result.ok) {
        if (redirectDoc) {
          window.location = redirectDoc
        } else {
          window.location = process.env.REACT_APP_EDXR_DOCS
        }
      }
    }
  }
}
