import React, { useEffect, useMemo, useState } from 'react'
import { BreadcrumbView } from '../../../../context/breadcrumb.context'
import { IconAdd } from '../../../../shared/icon/icon'
import { CardFullHeightScrollY } from '../../../../shared/card/card-full-height-scroll-y'
import { useApiFormAssessmentAssignment, useApiListAssessmentAssignment } from '../../../../api-new/state/assessment'
import { useLoad } from '../../../../shared/hooks/load'
import { useParamsInt } from '../../../../shared/hooks/location'
import { useNotificationAlertError } from '../../../../context/notification/type-alert'
import { Form, FormHiddenNumber, FormHiddenText, FormSubmitCancel } from '../../../../shared/form/form'
import { FormSelectObj } from '../../../../shared/form/form-select-obj'
import { Card } from '../../../../shared/card/card'
import { useHistory } from 'react-router-dom'

export const AssessmentManageAssignmentAdd = () => {
  const { assessmentID } = useParamsInt()

  const history = useHistory()

  const alert = useNotificationAlertError()

  const apiFormAssessmentAssignment = useApiFormAssessmentAssignment()
  const listAssessmentAssignmentSteps = useApiListAssessmentAssignment()
  const listAssessmentAssignmentStepObjectives = useApiListAssessmentAssignment()

  const [selectedStepIdString, updateSelectedStepIdString] = useState()
  const [selectedObjectiveIdString, updateSelectedObjectiveStepIdString] = useState()

  useLoad(() => listAssessmentAssignmentSteps.get(`${assessmentID}/steps`), [])

  useLoad(
    () => {
      if (selectedStepIdString) {
        listAssessmentAssignmentStepObjectives.get(`${assessmentID}/objectives/${selectedStepIdString}`)
      } else {
        listAssessmentAssignmentStepObjectives.init()
        updateSelectedObjectiveStepIdString(null)
      }
    },
    [selectedStepIdString]
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const selectedStep = useMemo(() => listAssessmentAssignmentSteps.items.find(i => i.idString === selectedStepIdString), [selectedStepIdString])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const selectedObjective = useMemo(() => listAssessmentAssignmentStepObjectives.items.find(i => i.idString === selectedObjectiveIdString), [
    selectedObjectiveIdString
  ])

  useEffect(
    () => {
      if (selectedObjective && selectedObjective.isAssignedTo) {
        const text = `Warning: Objectives can only be assigned to one assessment at a time. By proceeding you will replace the existing assignment to ${selectedObjective.isAssignedTo}.`
        alert.show(text, false)
      } else {
        alert.hide()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedObjective]
  )

  const handleSubmit = async data => {
    await apiFormAssessmentAssignment.post(data)
    history.goBack()
  }

  const handleClose = () => history.goBack()

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconAdd} text="Add">
        <CardFullHeightScrollY title="add assignment">
          <Card>
            <Form state={apiFormAssessmentAssignment.form} onSubmit={handleSubmit}>
              <FormHiddenNumber name="assessmentID" value={assessmentID} />
              <FormHiddenText name="stepTitle" value={selectedStep && selectedStep.title} />
              <FormHiddenText name="objectiveTitle" value={selectedObjective && selectedObjective.title} />

              <FormSelectObj
                className="col-12"
                label="step"
                name="stepIdString"
                state={listAssessmentAssignmentSteps}
                itemName="title"
                itemValue="idString"
                required
                small
                autoFocus
                onChange={updateSelectedStepIdString}
              />

              <FormSelectObj
                className="col-12"
                label="objective"
                state={listAssessmentAssignmentStepObjectives}
                name="objectiveIdString"
                itemName="title"
                itemValue="idString"
                required
                small
                disabled={!selectedStepIdString}
                onChange={updateSelectedObjectiveStepIdString}
              />

              <FormSubmitCancel small onCancel={handleClose} />
            </Form>
          </Card>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
