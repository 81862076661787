import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react'
import { Filter } from '../component/filter'
import { TableAG } from './table-ag'
import { getColumns } from './table-columns'
import { useGetQueryValues, useSetQueryValues } from './hooks'
import { QueryItems } from './component/table-query-items'

export const TableAgClient = forwardRef(({ children, apiList, sortCol, sortDesc, onReady, onRowClicked, onDestoryed }, ref) => {
  const tableRef = useRef()

  const [filter, updateFilter] = useState('')

  let columns = useMemo(() => getColumns(children), [children])

  const getQueryValues = useGetQueryValues()
  const setQueryValues = useSetQueryValues()

  const data = useMemo(
    () => {
      if (!apiList) {
        return []
      }

      return apiList.items
    },
    [apiList]
  )

  useEffect(
    () => {
      if (tableRef.current.api) {
        tableRef.current.api.setGridOption('quickFilterText', filter)
      }
    },
    [filter]
  )

  const handleReady = async agGrid => {

    onReady && (await onReady(agGrid))

    let queryValues = getQueryValues()

    if (queryValues.sortCol) {

      const columnState = {
        state: [{colId: queryValues.sortCol, sort: queryValues.sortDir}]
      }

      agGrid.api.applyColumnState(columnState)
      return
    }

    let colId = agGrid.api.getColumns().filter(column => column.visible).first(i => true).colId

    const columnState = {
      state: [{colId: sortCol || colId, sort: sortDesc ? 'desc' : 'asc'}]
    }

    agGrid.api.applyColumnState(columnState)
  }

  const handleDataUpdated = () => {

    if (data.length) {

      const queryValues = getQueryValues()

      if (queryValues.search) {
        updateFilter(queryValues.search)
      }

      if (queryValues.index !== undefined) {
        tableRef.current?.api?.ensureIndexVisible(queryValues.index, 'top')
      }
    }
  }

  const handleScrollVertical = index => {
    setQueryValues(undefined, index, undefined, undefined)
  }

  const handleFilterChange = filter => {
    updateFilter(filter)
    setQueryValues(filter, 0, undefined, undefined)
  }

  const handleClearState = () => {
    setQueryValues(undefined, 0, null, null)
    tableRef.current?.api?.resetColumnState()
    tableRef.current?.api?.paginationGoToPage(0)
  }

  const handleClearSearch = () => {
    updateFilter('')
    setQueryValues('', 0, undefined, undefined)
    tableRef.current?.api?.paginationGoToPage(0)
  }

  const handleSort = params => {

    let column = params.columns.findLast(() => true)

    if (column) {
      if (column.sort) {
        setQueryValues(undefined, undefined, column.colId, column.sort)
      } else {
        setQueryValues(undefined, undefined, null, null)
      }
      tableRef.current?.api?.ensureIndexVisible(0, 'top')
    }
  }

  return (
    <div className="d-flex flex-column h-100">
      <div>
        <QueryItems onClearSort={handleClearState} onClearSearch={handleClearSearch}  />
      </div>
      <div>
        <Filter value={filter} onChange={handleFilterChange} />
      </div>
      <div className="flex-fill">
        <TableAG
          ref={tableRef}
          funcRef={ref}
          columns={columns}
          data={data}
          sortFirst
          sortCol={sortCol}
          sortDesc={sortDesc}
          onReady={handleReady}
          onRefresh={handleReady}
          onRowClicked={onRowClicked}
          onScrollVertical={handleScrollVertical}
          onDataUpdated={handleDataUpdated}
          onSortChanged={handleSort}
          onDestoryed={onDestoryed}
        />
      </div>
    </div>
  )
})
