import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { AuthContext, useAuthContext, useRefresh } from '../../context/auth.context'
import { useLoad } from '../../shared/hooks/load'
import { AlertError } from '../../shared/alert/alert-error'

export const RedirectContentRoot = () => {
  const history = useHistory()
  const { refreshToken } = useParams()

  const [error, updateError] = useState()

  const authContext = useAuthContext(AuthContext)
  const { refreshRecreate } = useRefresh()

  const redirect = async () => {
    if (authContext.state.isAuth) {
      history.replace('/org/node-content')
    } else if (refreshToken) {
      await refreshRecreate(refreshToken)
    } else {
      history.replace(`/auth/email?redirect=/redirect/content-root`)
    }
  }

  useLoad(
    async () => {
      try {
        await redirect()
      } catch (e) {
        updateError(e)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [authContext.state.accessToken]
  )

  return (
    <React.Fragment>
      <AlertError error={error} />
      <div>COURSE ROOT REDIRECTING...</div>
    </React.Fragment>
  )
}
