import React from 'react'
import { Menu, MenuDetails } from '../../../../../../shared/menu/menu'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'
import { IconList } from '../../../../../../shared/icon/icon'
import { useApiListChunkEventLog } from '../../../../../../api-new/state/content'
import { TableAgInfinite } from '../../../../../../shared/table-builder/table-ag-infinite'
import { ColumnDateTime, ColumnMenu, ColumnText } from '../../../../../../shared/table-builder/table-columns'

export function LogEventList() {
  const apiListChunkEventLog = useApiListChunkEventLog()

  const handleGetRows = async (start, end, search, sortCol, sortDir) => {
    return await apiListChunkEventLog.get(`range/${start}/${end}?search=${search}&sort-col=${sortCol}&sort-dir=${sortDir}`)
  }

  const handleDestoryed = () => apiListChunkEventLog.init()

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconList} text="events">
        <CardFullHeightScrollY title="events">
          <TableAgInfinite onGetRows={handleGetRows} onDestoryed={handleDestoryed}>
            {/* <ColumnIndex /> */}
            <ColumnDateTime field="createdAt" />
            <ColumnText field="sessionID" />
            <ColumnText field="organization" />
            <ColumnText field="email" />
            <ColumnText field="subject" />
            <ColumnText field="path" />
            <ColumnText field="method" />
            <ColumnText field="ipAddress" />

            <ColumnText field="app" />
            <ColumnText field="appVersion" />
            <ColumnText field="deviceName" />
            <ColumnText field="deviceType" />
            <ColumnText field="deviceOS" />
            <ColumnText field="devicePlatform" />

            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuDetails to={`/admin/dashboard/log-event/list/edit/${row.id}`} show />
                </Menu>}
            />
          </TableAgInfinite>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
