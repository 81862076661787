import React from 'react'
import { useQueryParams } from '../../hooks/location'
import { IconClose } from '../../icon/icon'

export const QueryItems = ({ onClearSort, onClearSearch }) => {
  const params = useQueryParams(['sortCol', 'sortDir', 'search', 'index'])

  return (
    <React.Fragment>
      {params.sortCol &&
        <span className="badge text-bg-primary m-1 mt-2 ps-3 pe-2 py-2 text-capitalize" style={{ cursor: 'pointer' }} onClick={onClearSort}>
          sort by {params.sortCol} <span className="text-uppercase">{params.sortDir}</span> <IconClose />
        </span>}
      {params.search &&
        <span className="badge text-bg-primary m-1 mt-2 ps-3 pe-2 py-2 text-capitalize" style={{ cursor: 'pointer' }} onClick={onClearSearch}>
          search <IconClose />
        </span>}
    </React.Fragment>
  )
}
