import React from 'react'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { Menu, MenuAdd } from '../../../../../../shared/menu/menu'
import { useParamsInt } from '../../../../../../shared/hooks/location'
import { useApiFormLoginUser, useApiListLoginUser } from '../../../../../../api-new/state/content'
import { useApiListOrganization } from '../../../../../../api-new/state/content'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'
import { IconOrganization } from '../../../../../../shared/icon/icon'
import { useRoleSysLogin } from '../../../../../../role/hook/role-sys'
import { ColumnMenu, ColumnText } from '../../../../../../shared/table-builder/table-columns'
import { TableAgClient } from '../../../../../../shared/table-builder/table-ag-client'

export function OrganizationUserAdd() {
  const { loginID } = useParamsInt()

  const apiListOrganization = useApiListOrganization()

  const apiListLoginUser = useApiListLoginUser()
  const apiFormLoginUser = useApiFormLoginUser()

  const handleReady = async () => {
    await apiListLoginUser.get(`${loginID}/login`)
    await apiListOrganization.get()
  }

  const handleDestroyed = () => apiListOrganization.init()

  const handleCreate = async item => {
    await apiFormLoginUser.post({ organizationID: item.id, loginID: loginID })
    await apiListLoginUser.get(`${loginID}/login`)
  }

  const roleSysLogin = useRoleSysLogin()

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconOrganization} text="organization">
        <CardFullHeightScrollY title="add login to organization">
          <TableAgClient apiList={apiListOrganization} onReady={handleReady} onDestoryed={handleDestroyed}>
            <ColumnText field="name" />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuAdd text="add" onClick={() => handleCreate(row)} show={roleSysLogin.accessCreate} />
                </Menu>}
            />
          </TableAgClient>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
