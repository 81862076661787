import React from 'react'
import { BreadcrumbView } from '../../../../context/breadcrumb.context'
import { CardFullHeightScrollY } from '../../../../shared/card/card-full-height-scroll-y'
import { Menu, MenuPurge, MenuRestore } from '../../../../shared/menu/menu'
import { IconList } from '../../../../shared/icon/icon'
import { useParamsInt } from '../../../../shared/hooks/location'
import { useApiListUserAssessment } from '../../../../api-new/state/assessment'
import { useRouteMatch } from '../../../../../node_modules/react-router-dom/cjs/react-router-dom.min'
import { useRoleSysLogin } from '../../../../role/hook/role-sys'
import { TableAgClient } from '../../../../shared/table-builder/table-ag-client'
import { ColumnDateTime, ColumnMenu, ColumnText } from '../../../../shared/table-builder/table-columns'

export function AssessmentCourseRecycle() {
  const { url } = useRouteMatch()
  const { nodeID } = useParamsInt()

  const apiListUserAssessment = useApiListUserAssessment()

  const roleSysLogin = useRoleSysLogin()

  const handleReady = async () => await apiListUserAssessment.get(`deleted/${nodeID}/node`)
  const handleDestroyed = () => apiListUserAssessment.init()

  const handleRestore = async item => {
    await apiListUserAssessment.put(`${item.id}/restore`)
    await apiListUserAssessment.get(`deleted/${nodeID}/node`)
  }

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconList} text="recycle bin">
        <CardFullHeightScrollY title="recycle bin">
          <TableAgClient apiList={apiListUserAssessment} onReady={handleReady} onDestoryed={handleDestroyed}>
            <ColumnText field="assessment" sortable filter />
            <ColumnText field="email" sortable filter />
            <ColumnText field="firstName" sortable filter />
            <ColumnText field="lastName" sortable filter />
            <ColumnDateTime field="start" sortable filter />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuRestore onClick={() => handleRestore(row)} show={roleSysLogin.accessDelete} />
                  <MenuPurge to={`${url}/purge/${row.id}`} show={roleSysLogin.accessDelete} />
                </Menu>}
            />
          </TableAgClient>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
