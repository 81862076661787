import { useCallback } from 'react'
import { useHistory } from '../../../../../node_modules/react-router-dom/cjs/react-router-dom.min'
import { useRoleUser } from '../../../../api-new/state/content'

export const useBuildRouteRedirect = () => {
  return useCallback(path => {
    let url = new URL(path, process.env.REACT_APP_SITE)
    url.searchParams.set('redirect', btoa(`${document.location.pathname}${document.location.search}`))
    return url.toString()
  }, [])
}

export const useBuildRouteRedirectDoc = () => {
  return useCallback(path => {
    let url = new URL(path, process.env.REACT_APP_SITE)
    url.searchParams.set('redirect-doc', btoa(`${document.location.pathname}${document.location.search}`))
    return url.toString()
  }, [])
}

export const useBuildRouteArgs = () => {
  return useCallback((path, redirect, redirectDoc, email) => {
    let url = new URL(path, process.env.REACT_APP_SITE)

    if (redirect) {
      url.searchParams.set('redirect', redirect)
    }

    if (redirectDoc) {
      url.searchParams.set('redirect-doc', redirectDoc)
    }

    if (email) {
      url.searchParams.set('email', email)
    }

    return { path: `${url.pathname}${url.search}`, url: url.toString() }
  }, [])
}

export const useLoginRedirect = () => {
  const history = useHistory()

  const roleUser = useRoleUser()

  return useCallback(async () => {
    let roles = await roleUser.get()

    let isSysAdmin = roles.some(i => i.role === 'sys-admin')
    let isAdmin = roles.some(i => i.role === 'admin')
    let isAuthor = roles.some(i => i.role === 'author')
    let isOwner = roles.some(i => i.role === 'owner')
    let isTeacher = roles.some(i => i.role === 'teacher')
    // let isUser = roles.some(i => i.role === 'user')
    // let isLearner = roles && roles.some(i => i.role === 'learner')

    if (isSysAdmin || isAdmin || isAuthor || isOwner || isTeacher) {
      const searchParams = new URLSearchParams(document.location.search)

      let redirect = searchParams.get('redirect')

      if (redirect) {
        let path = atob(redirect)
        history.push(path)
        return
      }

      let redirectDoc = searchParams.get('redirect-doc')

      if (redirectDoc) {
        window.location = atob(redirectDoc)
        return
      }

      history.push('/org/dashboard')
      return
    }

    history.push('/download/links')

    return
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
