import React from 'react'
import { useApiListNodeGroup } from '../../../../../../api-new/state/content'
import { Menu, MenuAdd, MenuDelete } from '../../../../../../shared/menu/menu'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'
import { IconGroup } from '../../../../../../shared/icon/icon'
import { useModalActionID } from '../../../../../../shared/component/modal-dialog'
import { PermissionNodeGroupForNodeModal } from '../../../../../../shared/permission-modal/permission-node-group-for-node-module'
import { useRoleOrgNodeGroup } from '../../../../../../role/hook/role-org'
import { TableAgClient } from '../../../../../../shared/table-builder/table-ag-client'
import { ColumnText, ColumnMenu } from '../../../../../../shared/table-builder/table-columns'

export const PermissionNodeGroup = ({ nodeID }) => {
  const apiListNodeGroup = useApiListNodeGroup()

  const roleOrgNodeGroup = useRoleOrgNodeGroup()

  const [target, show, hide] = useModalActionID()

  const handleReady = () => apiListNodeGroup.get(`${nodeID}/node`)
  const handleDestroyed = () => apiListNodeGroup.init()

  const handleAddShow = () => show()
  const handleClose = () => hide()

  const handleClosed = async () => apiListNodeGroup.get(`${nodeID}/node`)

  const handleDelete = async item => {
    await apiListNodeGroup.delete(`${nodeID}/${item.groupID}`)
    await apiListNodeGroup.get(`${nodeID}/node`)
  }

  const HeaderMenu = () =>
    <Menu>
      <MenuAdd text="add" onClick={handleAddShow} show={roleOrgNodeGroup.accessCreate} />
    </Menu>

  return (
    <React.Fragment>
      <PermissionNodeGroupForNodeModal target={target} nodeID={nodeID} onClose={handleClose} onClosed={handleClosed} />
      <BreadcrumbView Icon={IconGroup} text="groups">
        <CardFullHeightScrollY title="groups" HeaderMenu={HeaderMenu}>
          <TableAgClient apiList={apiListNodeGroup} onReady={handleReady} onDestoryed={handleDestroyed}>
            <ColumnText field="groupName" header="Group" />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuDelete onClick={() => handleDelete(row)} show={roleOrgNodeGroup.accessDelete} />
                </Menu>}
            />
          </TableAgClient>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
