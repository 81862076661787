import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { ButtonDanger, ButtonPrimary } from '../legacy/button'
import { ModalDialog, useModalActionID } from './modal-dialog'
import { useLogout } from '../../context/auth.context'

const useWebWorker = workerFunction => {
  let [result, updateResult] = useState(null)
  let [loading, updateLoading] = useState(null)
  let [error, updateError] = useState(null)

  let [worker, updateWorker] = useState()
  let [scriptUrl, updateScriptURL] = useState()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoWorkerFunction = useCallback(workerFunction, [])

  const start = value => {
    try {
      const code = memoWorkerFunction.toString()
      const blob = new Blob([`(${code})()`], { type: 'application/javascript' })
      const scriptUrl = URL.createObjectURL(blob)
      let worker = new Worker(scriptUrl)

      updateWorker(worker)
      updateScriptURL(scriptUrl)

      updateLoading(() => true)

      worker.onmessage = e => {
        updateLoading(() => false)
        updateResult(() => e.data)
      }

      worker.onerror = e => {
        updateLoading(() => false)
        updateError(() => e.error)
      }

      worker.postMessage(value)
    } catch (e) {
      updateLoading(() => false)
      updateError(() => e.message)
    }
  }

  const stop = () => {
    worker.terminate()
    URL.revokeObjectURL(scriptUrl)
  }

  return [start, stop, result, loading, error]
}

const useCountdown = onComplete => {
  function workerCountdown() {
    this.onmessage = function(e) {
      let value = e.data

      this.postMessage(value)

      setInterval(() => this.postMessage(--value), [1000])
    }
  }

  let [start, stop, result, loading, error] = useWebWorker(workerCountdown)

  useEffect(
    () => {
      if (result === 0) {
        onComplete()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [result]
  )

  return [start, stop, result, loading, error]
}

export const Timeout = () => {
  const [countdownStart, countdownStop, countdownValue] = useCountdown(() => handleLogout())

  const logout = useLogout()

  const [modalID, modalShow, modalHide] = useModalActionID()

  const handleIdle = () => {
    let countdown = parseInt(process.env.REACT_APP_TIMEOUT_DELAY_SECONDS)
    countdownStart(countdown)
    pause()
    modalShow()
  }

  const { start, pause } = useIdleTimer({
    timeout: parseInt(process.env.REACT_APP_TIMEOUT_IDLE_SECONDS * 1000),
    throttle: 1000,
    onIdle: handleIdle
  })

  const handleContinue = () => {
    modalHide()
    countdownStop()
    start()
  }

  const handleLogout = () => {
    modalHide()
    countdownStop()
    logout()
  }

  const timeout = useMemo(
    () => {
      let min = Math.floor(countdownValue / 60)
      let sec = countdownValue - min * 60

      let minStr = min.toString().padStart(2, 0)
      let secStr = sec.toString().padStart(2, 0)

      return `${minStr}:${secStr}`
    },
    [countdownValue]
  )

  return (
    <React.Fragment>
      <ModalDialog target={modalID} header="Inactivity Warning" backdropStatic hideClose>
        <div className="py-2">
          Due to inactivity you will be signed out in {timeout}.
        </div>
        <div className="py-2">
          <ButtonPrimary text="Stay Signed In" onClick={handleContinue} />
          <ButtonDanger text="Sign Out Now" onClick={handleLogout} />
        </div>
      </ModalDialog>
    </React.Fragment>
  )
}
