import React from 'react'
import { Link } from 'react-router-dom'
import { useAuthContext } from '../../context/auth.context'
import { HeaderLogo } from './header-logo'
import { OffcanvasEnd, OffcanvasOpen, OffcanvasStart } from '../../shared/component/offcanvas'
import { SidebarRight } from './sidebar-right'
import { SidebarLeft } from './sidebar-left'
import { usePageContext } from '../../context/page.context'
import { IconBars, IconCaretDown, IconLoginDevice } from '../../shared/icon/icon'
import { AccessPinModal } from './accept-pin-login'
import { ModalLinkShow } from '../../shared/component/modal-dialog'
import { DisplayDesktop, DisplayMobile } from '../../shared/component/display'

const modalPinConfirmID = 'modal-pin-confirm'
const sidebarLeftTarget = 'sidebar-left-target'
const sidebarRightTarget = 'sidebar-right-target'

export const UnAuthHeader = () => {
  const pageContext = usePageContext()

  return (
    <div className="border-bottom" style={{ height: '58px' }}>
      <nav className="navbar navbar-expand p-0 h-100">
        <div className="container-fluid p-0 h-100">
          <div className="navbar-brand mx-3">
            <Link to="/auth/email">
              <img className={`logo ${pageContext.theme.isDark && 'logo-gray'} `} src="/EDXR-Logo.png" alt="header logo" />
            </Link>
          </div>

          <div className="collapse navbar-collapse">
            <ul className="navbar-nav">
              {pageContext.host.isLocalhost &&
                <li className="nav-item">
                  <span className="nav-link">
                    <PageSize show={pageContext.host.isLocalhost} />
                  </span>
                </li>}
            </ul>
          </div>

          <div className="collapse navbar-collapse edxr-pointer">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item d-none d-sm-block">
                <span className="nav-link fs-6 mt-1">
                  <Link to="/auth/email">Login</Link>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  )
}

export const AuthHeader = () => {
  const authContext = useAuthContext()
  const pageContext = usePageContext()

  return (
    <div className="border-bottom" style={{ height: '58px' }}>
      <nav className="navbar navbar-expand p-0 h-100">
        <div className="container-fluid p-0 h-100">
          <div className="navbar-brand d-xs-block d-lg-none mx-3">
            <DisplayDesktop>
              <OffcanvasOpen target={sidebarLeftTarget}>
                <IconBars className="me-2" size="lg" />
              </OffcanvasOpen>
              <HeaderLogo />
            </DisplayDesktop>
            <DisplayMobile>
              <OffcanvasOpen target={sidebarLeftTarget}>
                <IconBars className="me-2" size="lg" />
              </OffcanvasOpen>
              <HeaderLogo small />
            </DisplayMobile>
          </div>

          <div className="collapse navbar-collapse">
            <ul className="navbar-nav">
              {pageContext.host.isLocalhost &&
                <li className="nav-item">
                  <span className="nav-link">
                    <PageSize show={pageContext.host.isLocalhost} />
                  </span>
                </li>}
            </ul>
          </div>

          <div className="collapse navbar-collapse edxr-pointer">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item d-sm-none">
                <span className="nav-link">
                  <ModalLinkShow className="btn btn-light border mx-2" target={modalPinConfirmID} Icon={IconLoginDevice} />
                  <OffcanvasOpen target={sidebarRightTarget}>
                    <div className="btn btn-light border">
                      <IconCaretDown />
                    </div>
                  </OffcanvasOpen>
                </span>
              </li>

              <li className="nav-item d-none d-sm-block">
                <span className="nav-link">
                  <ModalLinkShow className="btn btn-light border mx-2" target={modalPinConfirmID} Icon={IconLoginDevice} text="Login another device" />

                  <OffcanvasOpen target={sidebarRightTarget}>
                    <div className="btn btn-light border">
                      {authContext.state.jwtClaims.email}
                      <IconCaretDown />
                    </div>
                  </OffcanvasOpen>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="d-xs-block d-lg-none">
        <OffcanvasSidebarLeft target={sidebarLeftTarget} />
      </div>
      <OffcanvasSidebarRight target={sidebarRightTarget} />
      <AccessPinModal target={modalPinConfirmID} />
    </div>
  )
}

const OffcanvasSidebarLeft = ({ target }) => {
  return (
    <OffcanvasStart target={target} width="260">
      <SidebarLeft target={target} />
    </OffcanvasStart>
  )
}

const OffcanvasSidebarRight = ({ target }) => {
  const authContext = useAuthContext()

  return (
    <OffcanvasEnd target={target} header={authContext.state.organizationName} login={authContext.state.jwtClaims.email} width="300">
      <SidebarRight target={target} />
    </OffcanvasEnd>
  )
}

const PageSize = () => {
  return (
    <React.Fragment>
      <span className="edxr-d-xs">EX SMALL</span>
      <span className="edxr-d-sm">SMALL</span>
      <span className="edxr-d-md">MEDIUM</span>
      <span className="edxr-d-lg">LARGE</span>
      <span className="edxr-d-xl">EX LARGE</span>
      <span className="edxr-d-xxl">EX EX LARGE</span>
    </React.Fragment>
  )
}
