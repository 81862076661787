import React from 'react'
import { useRouteMatch } from '../../../../../../node_modules/react-router-dom/cjs/react-router-dom.min'
import { useParamsInt } from '../../../../../shared/hooks/location'
import { useApiListAnalyticTopic } from '../../../../../api-new/state/analytics'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { IconList } from '../../../../../shared/icon/icon'
import { CardFullHeightScrollY } from '../../../../../shared/card/card-full-height-scroll-y'
import { TableAgClient } from '../../../../../shared/table-builder/table-ag-client'
import { ColumnMenu, ColumnText } from '../../../../../shared/table-builder/table-columns'
import { Menu, MenuPurge, MenuRestore } from '../../../../../shared/menu/menu'

export const AnalyticCourseTopicRecycle = () => {
  const { url } = useRouteMatch()
  const { nodeID } = useParamsInt()

  const apiListAnalyticTopic = useApiListAnalyticTopic()

  const handleReady = () => apiListAnalyticTopic.get(`${nodeID}/node/deleted`)
  const handleDestroyed = () => apiListAnalyticTopic.init()

  const handleRestore = async data => {
    await apiListAnalyticTopic.put(`${data.id}/restore`)
    await apiListAnalyticTopic.get(`${nodeID}/node/deleted`)
  }

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconList} text="Recycle">
        <CardFullHeightScrollY title="Recycle Bin">
          <TableAgClient apiList={apiListAnalyticTopic} onReady={handleReady} onDestoryed={handleDestroyed}>
            <ColumnText field="name" />
            <ColumnText field="description" />
            <ColumnText field="analyticCount" header="Analytics" />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuRestore onClick={() => handleRestore(row)} show />
                  <MenuPurge to={`${url}/${row.id}/purge`} show />
                </Menu>}
            />
          </TableAgClient>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
