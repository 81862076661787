import React from 'react'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { IconList } from '../../../../../../shared/icon/icon'
import { useParamsInt } from '../../../../../../shared/hooks/location'
import { Menu, MenuItem, MenuRestore } from '../../../../../../shared/menu/menu'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'
import { useApiListAppStore } from '../../../../../../api-new/state/config'
import { IconPurge } from '../../../../../../shared/icon/icon'
import { useRoleSysApp } from '../../../../../../role/hook/role-sys'
import { TableAgClient } from '../../../../../../shared/table-builder/table-ag-client'
import { ColumnText, ColumnMenu } from '../../../../../../shared/table-builder/table-columns'

export const AppStoreRecycle = () => {
  const { appID } = useParamsInt()

  const apiListAppStore = useApiListAppStore()

  const roleSysApp = useRoleSysApp()

  const handleReady = () => apiListAppStore.get('deleted')
  const handleDestroyed = () => apiListAppStore.init()

  const handleRestore = async id => {
    await apiListAppStore.put(`${id}/restore`)
    await apiListAppStore.get('deleted')
  }

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconList} text="recycle bin">
        <CardFullHeightScrollY title="recycle bin">
          <TableAgClient apiList={apiListAppStore} onReady={handleReady} onDestoryed={handleDestroyed}>
            <ColumnText field="name" />
            <ColumnText field="key" />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuRestore onClick={() => handleRestore(row.id)} show={roleSysApp.accessDelete} />
                  <MenuItem Icon={IconPurge} text="purge" to={`/admin/app/edit/${appID}/store/recycle/purge/${row.id}}`} show={roleSysApp.accessPurge} pin />
                </Menu>}
            />
          </TableAgClient>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
