import React from 'react'
import { useParams, useRouteMatch } from 'react-router'
import { BreadcrumbView } from '../../context/breadcrumb.context'
import { IconList } from '../../shared/icon/icon'
import { CardFullHeightScrollY } from '../../shared/card/card-full-height-scroll-y'
import { Menu, MenuDelete, MenuItem } from '../../shared/menu/menu'
import { useApiListHeatmapNodeSession } from '../../api-new/state/heatmap'
import { IconEnter } from '../../shared/icon/icon'
import { TableAgClient } from '../../shared/table-builder/table-ag-client'
import { ColumnDateTime, ColumnMenu, ColumnText, ColumnDuration } from '../../shared/table-builder/table-columns'

export function SessionNodeList() {
  const { url } = useRouteMatch()
  const { nodeID } = useParams()

  const apiListHeatmapNodeSession = useApiListHeatmapNodeSession()

  const handleReady = async () => await apiListHeatmapNodeSession.get(nodeID)
  const handleDestroyed = () => apiListHeatmapNodeSession.init()

  const handleDelete = async id => {
    await apiListHeatmapNodeSession.delete(id)
    await apiListHeatmapNodeSession.get(nodeID)
  }

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconList} text="sessions">
        <CardFullHeightScrollY title="Sessions">
          <TableAgClient apiList={apiListHeatmapNodeSession} onReady={handleReady} onDestoryed={handleDestroyed}>
            <ColumnDateTime field="beganAt" header="Began At" />
            <ColumnDateTime field="endedAt" header="Ended At" />
            <ColumnDuration field="duration" beganAt="beganAt" endedAt="endedAt" />

            <ColumnText field="email" />
            <ColumnText field="firstName" />
            <ColumnText field="lastName" />

            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuItem text="view" Icon={IconEnter} to={`${url}/series/${row.sessionID}`} show pin />
                  <MenuDelete onClick={() => handleDelete(row.sessionID)} show />
                </Menu>}
            />
          </TableAgClient>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
