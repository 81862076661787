import React from 'react'
import { Route } from 'react-router-dom'
import { TradeShowList } from './content/tradeshow-list'
import { TradeshowRecycle } from './content/tradeshow-recycle'
import { TradeShowAdd } from './content/tradeshow-add'
import { TradeShowEdit } from './content/tradeshow-edit'
import { Delete } from '../../../../shared/page/page-delete'
import { Purge } from '../../../../shared/page/page-purge'
import { useApFormTradeshow } from '../../../../api-new/state/content'
import { LogEventOrg } from '../../../../shared/page/log-event/log-event-org'

export const LayoutTradeshow = () => {
  return (
    <React.Fragment>
      <Route path="/org/tradeshow/list" component={TradeShowList} />
      <Route path="/org/tradeshow/list/add" component={TradeShowAdd} />
      <Route path="/org/tradeshow/list/edit/:tradeshowID" component={TradeShowEdit} />
      <Route path="/org/tradeshow/list/edit/:deleteID/delete">
        <Delete useApiForm={useApFormTradeshow} titleParam="name" successPath="/org/tradeshow/list" />
      </Route>

      <Route path="/org/tradeshow/list/edit/:tradeshowID/activity-id/:id">
        <LogEventOrg subject="tradeshow" />
      </Route>

      <Route path="/org/tradeshow/list/recycle" component={TradeshowRecycle} />
      <Route path="/org/tradeshow/list/recycle/purge/:purgeID">
        <Purge useApiForm={useApFormTradeshow} titleParam="name" successPath="/org/tradeshow/list/recycle" />
      </Route>
      <Route path="/org/tradeshow/list/activity-all">
        <LogEventOrg subject="tradeshow" />
      </Route>
    </React.Fragment>
  )
}
